import { Component, Input } from '@angular/core';

@Component({
    selector: 'intm-text-low-l-low-m',
    template: `
        <div class="
                font-helvetica-now-regular font-regular text-18 leading-24 -tracking-0-8
                m:text-16 m:leading-22 m:-tracking-0-6
            "
             [ngClass]="{
             'text-content-high': color=='high',
             'text-content-mid': color=='mid',
             'text-content-low': color=='low',
             'text-content-oncolor-high': color=='oncolor-high',
             'text-content-oncolor-mid': color=='oncolor-mid'
             }"
        >
            <ng-content></ng-content>
        </div>
    `
})
export class TextLowLLowMComponent {
    @Input() color: 'high' | 'mid' | 'low' | 'oncolor-high' | 'oncolor-mid'= 'high';
}
