import { Component, Input } from '@angular/core';
import { LabelType } from "../label.interface"

@Component({
    standalone: true,
    selector: 'intm-simple-label',
    template: `
        <div class="mb-4">
            <label [for]="for" class="font-helvetica-now-regular font-regular text-14 leading-20 -tracking-0-5 {{ disabled ? 'opacity-25 cursor-not-allowed' : '' }}" >
                <ng-content></ng-content>
            </label>
        </div>
    `
})
export class SimpleLabelComponent implements LabelType{
    @Input() for: string = ""
    @Input() disabled: boolean = false
}
