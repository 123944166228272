@if (documents) {
    <div class="xs:flex xs:gap-8 xs:flex-col">
        @for(document of documents; track item ){
            <div  class="flex items-center justify-between xs:py-12 xs:px-8 xs:border xs:border-stroke-low xs:rounded-8">
                <div class="flex gap-8 items-center">
                        @switch(document.status){
                            @case("pending"){
                                <div class="flex items-center gap-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 16.0415C13.4518 16.0415 16.25 13.3189 16.25 9.96032C16.25 7.79416 15.086 5.89252 13.3333 4.81528M10 3.87914C6.54822 3.87914 3.75 6.60178 3.75 9.96032C3.75 12.1265 4.91403 14.0281 6.66667 15.1054M11.4292 13.7498L9.63647 16.3923L12.6312 17.6316M7.56276 2.10352L10.3963 3.66067L8.31121 6.0917" stroke="#05A36E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <intm-text-low-s color="semantic-success">
                                        {{document.progress}}%
                                    </intm-text-low-s>
                                </div>
                            }
                            @case('ok'){
                                <div class="w-20 h-20">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <path d="M5.83268 11.9261L7.91602 14.0094L13.7493 8.17611M18.3327 10.6761C18.3327 15.2785 14.6017 19.0094 9.99935 19.0094C5.39698 19.0094 1.66602 15.2785 1.66602 10.6761C1.66602 6.07373 5.39698 2.34277 9.99935 2.34277C14.6017 2.34277 18.3327 6.07373 18.3327 10.6761Z" stroke="#05A36E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            }
                            @case('error'){
                                <div class="w-20 h-20">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                        <path d="M13.8332 7.00391L10.4998 10.3372M10.4998 10.3372L7.1665 13.6706M10.4998 10.3372L7.1665 7.00391M10.4998 10.3372L13.8332 13.6706M18.8332 10.3372C18.8332 14.9396 15.1022 18.6706 10.4998 18.6706C5.89746 18.6706 2.1665 14.9396 2.1665 10.3372C2.1665 5.73487 5.89746 2.00391 10.4998 2.00391C15.1022 2.00391 18.8332 5.73487 18.8332 10.3372Z" stroke="#D92626" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </div>
                            }
                            @case ('delete') {
                                <intm-text-low-s [color]="'semantic-danger'">
                                    {{ textDelete }}
                                </intm-text-low-s>
                            }
                        }

                    <span class="font-helvetica-now-regular font-regular text-14 leading-20 -tracking-0-5 text-content-mid">
                        {{ document.file.name }}
                    </span>
                </div>
                <div>
                    <button  (click)="remove(document, $index)" class="font-helvetica-now-regular font-regular text-14 leading-20 -tracking-0-5 text-content-low hover:text-semantic-danger xs:hidden">
                        {{ textRemove }}
                    </button>
                    <div class="hidden xs:block">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M3.16675 5.25H14.8334L14.0001 17.3333H4.00008L3.16675 5.25Z" stroke="#0080F6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M9 7.33301V14.833" stroke="#0080F6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M6.0835 7.33301L6.50016 14.833" stroke="#0080F6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.9167 7.33301L11.5001 14.833" stroke="#0080F6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3.16675 4.75C3.16675 3.64543 4.06218 2.75 5.16675 2.75H12.8334C13.938 2.75 14.8334 3.64543 14.8334 4.75V5.25H3.16675V4.75Z" stroke="#0080F6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.3335 2.74984V2.33317C7.3335 1.4127 8.07969 0.666504 9.00016 0.666504V0.666504C9.92064 0.666504 10.6668 1.4127 10.6668 2.33317V2.74984" stroke="#0080F6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M0.666748 5.25H17.3334" stroke="#0080F6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        }
    </div>
} 