import { Component, Input, signal, OnInit  } from '@angular/core';
import { CommonModule } from "@angular/common"
import { NotificationIntmService } from './notification.service'
import { INotificationIntm } from './notification.interface';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'intm-container-notification',
    templateUrl: './notification.component.html'
})
export class NotificationIntmContainerComponent {
    constructor(
        public notificationIntmService: NotificationIntmService
    ){}

    toggleIsVisible(notification: INotificationIntm){
        this.notificationIntmService.removeNotification(notification)
    }
}
