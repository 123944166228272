@if(label){
    <intm-simple-label>
        {{ label }}
    </intm-simple-label>
}
<div class="relative flex items-center bg-base">
        <input
            #searchInput
            type="text"
            (input)="onSearch($event)"
            [placeholder]="placeholder"
            [id]="id"
            [name]="name"
            [value]="control && control?.value && control?.value.text"
            (blur)="closeOnBlur($event)"
            autocomplete="off"
            class="w-full px-16 rounded-8 h-[40px] xs:h-48 font-helvetica-now-regular font-regular text-14 xs:text-16 leading-20 -tracking-0-5 {{control && control.touched && control.invalid ? 'focus:outline-none shadow-semantic-danger': 'shadow-stroke-low focus:outline-none focus:shadow-semantic-primary'}} placeholder:text-content-low"
            (click)="open()"
        />
        <div class="absolute right-16 hover:cursor-pointer" (click)="openSearch()">
            @if (isOpen) {
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                >
                    <path d="M6 15L12 9L18 15"
                          stroke="#131D34"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                    />
                </svg>
            } @else {
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M6 9L12 15L18 9"
                        stroke="#131D34"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            }

        </div>
        @if (isOpen && searchTerm === '') {
            <div class="absolute w-full top-full left-0 pt-8 pb-8 mt-4 bg-base divide-y divide-gray-100 rounded-8 shadow-medium transition duration-300 overflow-y-auto h-fit max-h-[200px] z-50">
                <ul class="list-none p-0 m-0">
                    @if (options.length) {
                        @if(showAllResults){
                            @for(res of options; track $index){
                                <li class="py-12 px-16 hover:cursor-pointer hover:bg-alt active:bg-surface-blue2"
                                    (click)="onChanged(res)"
                                >
                                    {{ res.text }}
                                </li>
                            }
                        } @else {
                            @for(res of options; track $index){
                                @if ( $index <= limit) {
                                    <li class="py-12 px-16 hover:cursor-pointer hover:bg-alt active:bg-surface-blue2"
                                        (click)="onChanged(res)"
                                    >
                                        {{ res.text }}
                                    </li>
                                }
                            }
                        }
                    } @else {
                        <li class="py-12 px-16 hover:cursor-not-allowed">
                            <intm-text-low-xs color="mid">
                                Not options
                            </intm-text-low-xs>
                        </li>
                    }
                </ul>
            </div>
        }
        @if(results.length > 0 && searchTerm != ''){
            <div class="absolute w-full top-full left-0 pt-8 pb-8 mt-4 bg-base divide-y divide-gray-100 rounded-8 shadow-medium transition duration-300 overflow-y-auto h-fit max-h-[200px] z-50">
                <ul class="list-none p-0 m-0">
                    @for(res of results; track res){
                        <li class="py-12 px-16 hover:cursor-pointer hover:bg-alt active:bg-surface-blue2"
                            (click)="onChanged(res)"
                            [innerHTML]="res.text | bold: searchTerm"
                        >
                        </li>
                    }
                </ul>
            </div>
        }
        @if(results.length == 0 && showNotFound() && searchTerm != ''){
            <div class="absolute w-full top-full left-0 pt-8 pb-8 mt-4 bg-alt rounded-8 shadow-medium transition duration-300 overflow-y-auto h-fit max-h-[200px] z-50">
                <ul class="list-none p-0 m-0">
                    <li class="py-12 px-16 hover:cursor-not-allowed">
                        <intm-text-low-xs color="mid">
                            {{notFound}}
                        </intm-text-low-xs>
                    </li>
                </ul>
            </div>
        }
    </div>
@if(control && control.touched && control.invalid){
    <intm-message-helper-error [control]="control"></intm-message-helper-error>
}
