<div class="flex items-center justify-between px-32 py-16 border-stroke-low xs:px-16" [class.border-b]="hasBorder">
    <div>
        <a class="py-16 w-full">
            @if (isManaDomain) {
                <img src="assets/images/logos/logo-mana-horizontal.svg" alt="logo-intm" class="h-24">
            }
            @else {
                <img src="assets/images/logos/logo-horizontal.svg" alt="logo-intm">
            }
        </a>
    </div>
 </div>