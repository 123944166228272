export interface Insurance {
    id: number,
    idDyn: number,
    apiVersion?: number,
    version?: 0,
    unsuscribeDate?: string | null,
    effectDate?: string | null,
    entryDate?: string | null,
    bookingReference1?: string,
    bookingReference2?: string,
    warranties?: string,
    policy?: {
        idDyn?: number,
        policyNumber?: string,
        teleMedicine?: boolean,
        product?: {
            idDyn?: number,
            productName?: string,
            dischargeDate?: string,
            logoPath?: string,
            backgroundPath?: string
        },
        documentList?: [],
        companyDivision?: {
            idDyn?: number,
            contactInfoList?: IContactInfoList[]
        }
    },
    quotePresetList?: Array<
        {
            id?: number,
            paxNum?: number,
            countryDestiny?: string,
            priceListParamsValues1?: IPriceListParamsValues,
            priceListParamsValues2?: IPriceListParamsValues,
            priceListParamsValues3?: IPriceListParamsValues | null
        }
    >,
    totalAmount?: number,
    clientComissionAmount?: number,
    netRetailPrice?: number,
    paymentProof?: string | null,
    user?: IUserInsurance,
    createdDate?: string,
    countryDestiny?: string,
    countryOrigin?: string,
    policyNumber?: string,
    travelEndDate?: string,
    travelStartDate?: string,
    travelAgency?: string,
    insurance?: Insurance,
    insuranceInsuredList?: IinsuranceInsuredList[],
    paxNum: number | null,
    priceListParamsValues1?: IPriceListParamsValues | string | null,
    priceListParamsValues2?: IPriceListParamsValues | string | null,
    flags?: IFlags[],
    salePoint?: {
        commercialName?: string
    }
}
export interface IUserInsurance {
    id?: number,
    idDyn?: number,
    documentType?: string,
    title?: string,
    documentNumber?: string,
    username?: string,
    name?: string,
    birthDate?: string,
    surname?: string,
    addressInfoList?: IAddressInfoList[],
    contactInfoList?: IContactInfoList[]
    salePoint?: {
        commercialName: string
    }
}
export interface ICountry {
    idDyn?: number,
    name?: string,
    isoCode3?: string
}
export interface IinsuranceInsuredList {
    id: number,
    insured: Insured
}
export interface Insured {
    id: number,
    idDyn: number,
    name: string,
    surname: string,
    documentNumber: string,
    documentType: string,
    addressInfoList: IAddressInfoList[],
    contactInfoList: IContactInfoList[]
}
export interface IAddressInfoList {
    id: number,
    commercialAddress: string,
    commercialCity: string,
    commercialPostalCode: string,
    commercialCountry: ICountry,
    commercialProvince: ICountry | null,
    fiscalAddress: string | null,
    fiscalCity: string | null,
    fiscalPostalCode: string | null,
    fiscalProvince: string | null,
    fiscalCountry: string | null
}
export interface IContactInfoList {
    id?: number,
    apiUpdateDate?: string,
    contactType?: string,
    email?: string,
    mobileNumber?: string,
    phoneNumber?: string | null,
    isFirsth?: string
}
export interface IPriceListParamsValues {
    idDyn: number,
    name: string,
    minRange: number,
    maxRange: number,
    priceListParams: {
        idDyn: number,
        name: string,
        plan: number,
        paramType: string
    }
}

export interface IFlags {
    name: string,
    value: boolean,
    message: string,
}

export const InsuranceEmpty: Insurance = {
    id: 0,
    idDyn: 0,
    apiVersion: 0,
    version: 0,
    unsuscribeDate: "",
    effectDate: "",
    entryDate: "",
    bookingReference1: "",
    bookingReference2: "",
    warranties: "",
    policy: {
        idDyn: 0,
        policyNumber: "",
        teleMedicine: false,
        product: {
            idDyn: 0,
            productName: "",
            dischargeDate: "",
            logoPath: "",
            backgroundPath: ""
        },
        documentList: [],
        companyDivision: {
            idDyn: 0,
            contactInfoList: [
                {
                    phoneNumber: "",
                    mobileNumber: ""
                }
            ]
        }
    },
    quotePresetList: [
        {
            id: 0,
            paxNum: 0,
            countryDestiny: "",
            priceListParamsValues1: {
                idDyn: 0,
                name: "",
                minRange: 0,
                maxRange: 0,
                priceListParams: {
                    idDyn: 0,
                    name: "",
                    plan: 0,
                    paramType: ""
                }
            },
            priceListParamsValues2: {
                idDyn: 0,
                name: "",
                minRange: 0,
                maxRange: 0,
                priceListParams: {
                    idDyn: 0,
                    name: "",
                    plan: 0,
                    paramType: ""
                }
            },
            priceListParamsValues3: null
        }

    ],
    totalAmount: 0,
    clientComissionAmount: 0,
    netRetailPrice: 0,
    paymentProof: "",
    user: {
        id: 0,
        idDyn: 0,
        documentType: "",
        title: "",
        documentNumber: "",
        username: "",
        name: "",
        birthDate: "",
        surname: "",
        addressInfoList: [
            {
                id: 0,
                commercialAddress: "",
                commercialCity: "",
                commercialPostalCode: "",
                fiscalAddress: "",
                fiscalCity: "",
                fiscalPostalCode: "",
                commercialCountry: {
                    idDyn: 0,
                    name: ""
                },
                fiscalCountry: "",
                commercialProvince: {
                    idDyn: 0,
                    name: ""
                },
                fiscalProvince: ""
            }
        ],
        contactInfoList: [
            {
                id: 0,
                apiUpdateDate: "",
                contactType: "",
                email: "",
                mobileNumber: "",
                phoneNumber: "",
                isFirsth: ""
            }
        ],
        salePoint: {
            commercialName: ""
        }
    },
    createdDate: "",
    countryDestiny: "",
    countryOrigin: "",
    policyNumber: "",
    travelEndDate: "",
    travelStartDate: "",
    travelAgency: "",
    paxNum: null
}
