import { Injectable, signal } from '@angular/core';
import { INotificationIntm } from './notification.interface';

@Injectable({
    providedIn: 'root',
})
export class NotificationIntmService {
    public notifications = signal<INotificationIntm[]>([]);
    private nextId = 0;
    public showContainer = signal<boolean>(false);

    /**
     * Show a notification
     * @param type
     * @param message
     * @param duration
     */
    public show(type: string, message: string, duration: number = 0) {
        if (
            type === 'error' ||
            type === 'warning' ||
            type === 'success' ||
            type === 'info'
        ) {
            const id = this.nextId++;
            const notification: INotificationIntm = {
                id,
                type,
                message,
                duration,
                isVisible: true
            };
            this.notifications.update(() => [
                notification,
            ]);
            this.toogleContainer();

            if (duration > 0) {
                setTimeout(() => {
                    this.removeNotification(notification);
                }, duration);
            }
        } else {
            throw new Error('Invalid type');
        }
    }

    public removeNotification(notification: INotificationIntm) {
        notification.isVisible = false;
        // Este setTimeout sirve para dar tiempo a que se ejecute la transición fadeout
        setTimeout(() => {
            this.notifications.update((notifications) =>
                notifications.filter((item) => item.id !== notification.id)
            );
            this.toogleContainer();
        }, 300);
    }

    private toogleContainer() {
        if (this.notifications().length > 0) {
            this.showContainer.set(true);
        } else {
            this.showContainer.set(false);
        }
    }
}
