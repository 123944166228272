import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { ORIGIN_APP } from "@app/utils/const";
import { environment } from "@environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ToolService {
    constructor(
        private storage: StorageService
    ){}

    /**
     * Función encargada de convertir codificación Base64 a Blob
     *
     * @param base64Data
     * @param contentType
     */
    public base64toBlob(base64Data: any, contentType: any) {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            let bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    getApiDomain(): string {
        return this.storage.get("origin") === ORIGIN_APP.MANA ? environment.API_APP : environment.API_AC;
    }

    addScriptInContainer(container: HTMLDivElement, script: { innerHTML: string, id: string, src: string, async?: boolean, defer?: boolean, culture?: string, cbid?: string, blockingmode?: string }) {
        let scriptCreate = document.createElement("script");
        scriptCreate.innerHTML = script.innerHTML ? script.innerHTML : "";
        scriptCreate.id = script.id ? script.id : "";
        scriptCreate.src = script.src ? script.src : "";
        scriptCreate.setAttribute('data-cbid', script.cbid ? script.cbid : '');
        scriptCreate.setAttribute('data-blockingmode', script.blockingmode ? script.blockingmode : '');
        scriptCreate.async = script.async ? script.async : false;
        scriptCreate.defer = script.defer ? script.defer : false;
        scriptCreate.type = "text/javascript";
        scriptCreate.dataset['culture'] = script.culture ? script.culture: '';
        container.appendChild(scriptCreate);
    }

    getBrowserNameAndVersion() {
        let ua = navigator.userAgent,
            tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { name: 'IE', version: (tem[1] || '') };
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\bEdg\/(\d+)/);
            if (tem != null) {
                return { name: 'Edge(Chromium)', version: tem[1] };
            }
            tem = ua.match(/\bOPR\/(\d+)/);
            if (tem != null) {
                return { name: 'Opera', version: tem[1] };
            }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) {
            M.splice(1, 1, tem[1]);
        }
        return {
            name: M[0],
            version: M[1]
        };
    }

}