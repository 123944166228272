import { Injectable, signal } from  '@angular/core';
import { StorageService } from './storage.service';
import { ORIGIN_APP } from '@app/utils/const';
import { Title } from '@angular/platform-browser';

@Injectable()
export class OriginService {

    origin = signal<string>(ORIGIN_APP.INTERMUNDIAL);

    constructor(
        private storage: StorageService,
        private titleService: Title
    ) {}

    setOrigin(origin: string) {
        this.storage.set("origin", origin);
        this.origin.set(origin);
        if (origin === ORIGIN_APP.MANA) {
            this.titleService.setTitle('Mana');
            this.setFavicon('assets/images/logos/favicon-mana.png');
        }
    }

    setFavicon(url: string) {
        let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        link.href = url;
    }
}
