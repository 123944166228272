<label class="inline-flex items-center gap-8 cursor-pointer {{disabled ? 'opacity-25 hover:cursor-not-allowed' : ''}} h-24 s:h-28" [for]="id">
    <div
        class="relative flex items-center cursor-pointer"
        data-ripple-dark="true"
    >
        <input
            [id]="id"
            type="checkbox"
            class="before:content[''] peer relative h-16 w-16 cursor-pointer appearance-none rounded-4 border {{control && control.touched && control.invalid ? 'border-semantic-danger': 'border-content-high'}}  transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-16 before:w-16 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-semantic-primary before:opacity-0 before:transition-opacity checked:border-semantic-primary checked:bg-semantic-primary checked:before:bg-semantic-primary hover:before:opacity-5 disabled:cursor-not-allowed"
            [disabled]="disabled"
            [checked]="value"
            (change)="onChanged($event)"
            (blur)="regOnTouched($event)"
        />
        <span
            class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
            >
                <path
                    d="M1 4.99988L2.99951 6.99988L9 1.00012"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </span>
    </div>
    <ng-content></ng-content>
</label>
