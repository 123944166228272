<div class="{{class}} flex flex-col focus:outline-none {{ control && control.disabled ? 'opacity-50': ''}}" tabindex="0" (blur)="closeOnBlur($event)" #dropdownContainer
    (keyup)="typingValue($event);searchValueTyped()"
>
    <div class="relative group {{ showSelect ? 'z-50' : 'z-30' }}">
        <div
            class="{{class}} border {{ showSelect ? 'border-semantic-primary' : control && control.touched && control.invalid ? 'border-semantic-danger': 'border-stroke-low' }} rounded-8 h-40 xs:h-48 flex items-center justify-between px-16 {{ control && control.disabled ? 'hover:cursor-not-allowed':'hover:cursor-pointer' }} outline-stroke-low bg-base"
            (click)="toggle()"
        >

            @if (value) {
                <intm-text-low-s>
                    {{ value?.text }}
                </intm-text-low-s>
            }
            @else {
                <intm-text-low-s color="low">
                    {{placeholder}}
                </intm-text-low-s>
            }
            @if (showSelect) {
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path d="M6 15L12 9L18 15"
                        stroke="#131D34"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            }
            @else {
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M6 9L12 15L18 9"
                        stroke="#131D34"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            }

        </div>
        <ul
            #selectList
            class="{{showSelect ? 'block' : 'hidden' }} absolute z-[55] w-full left-0 pt-8 pb-8 mt-4 bg-white divide-y divide-gray-100 rounded-md shadow-lg transition duration-300 overflow-y-auto h-fit max-h-[120px]"
        >
            @for (option of options; track $index) {
                @if (option.disable) {
                    <li class="py-12 px-16 hover:cursor-not-allowed opacity-25"
                        #listItem
                    >
                        {{ option.text }}
                    </li>
                }
                @else {
                    <li class="py-12 px-16 hover:cursor-pointer hover:bg-alt active:bg-surface-blue2 focus-within:shadow-lg {{ control && control.value && (control.value['text'] == option.text || control.value['value'] == option.value) ? 'bg-surface-blue2' : ''}}"
                        (click)="onChanged(option)"
                        #listItem
                    >
                        {{ option.text }}
                    </li>
                }
            }
            @empty {
                <li class="py-12 px-16 hover:cursor-not-allowed opacity-25">
                    <intm-text-low-xs>
                        No hay elementos
                    </intm-text-low-xs>
                </li>
            }
        </ul>
    </div>
</div>
@if(control && control.touched && control.invalid){
    <intm-message-helper-error [control]="control"></intm-message-helper-error>
}
