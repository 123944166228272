import { Component, Input, signal, OnInit  } from '@angular/core';
import { CommonModule } from "@angular/common"

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'intm-tooltip',
    templateUrl: './tooltip.component.html'
})
export class TooltipComponent implements OnInit{
    @Input() textContent: string = '';
    @Input() title: string = '';
    @Input() position: 'top-center' | 'top-left' | 'top-right' | 'bottom-center' | 'bottom-left' | 'bottom-right'  = 'top-center';
    public isBottomPosition: boolean = false;
    public isTopPosition: boolean = false;
    public isLeftPositionArrow: boolean = false;
    public isRightPositionArrow: boolean = false;
    public isCenterPositionArrow: boolean = false;

    isVisible = signal<boolean>(false);

    constructor() {}
    ngOnInit(): void {
        this.setPositions();
    }

    toogleIsVisible(){
        this.isVisible.set(!this.isVisible());
    }

    private setPositions(){
        switch(this.position) {
            case 'top-center':
                this.isTopPosition = true;
                this.isCenterPositionArrow = true;
                break;
            case 'top-left':
                this.isTopPosition = true;
                this.isLeftPositionArrow = true;
                break;
            case 'top-right':
                this.isTopPosition = true;
                this.isRightPositionArrow = true;
                break;
            case 'bottom-center':
                this.isBottomPosition = true;
                this.isCenterPositionArrow = true;
                break;
            case 'bottom-left':
                this.isBottomPosition = true;
                this.isLeftPositionArrow = true;
                break;
            case 'bottom-right':
                this.isBottomPosition = true;
                this.isRightPositionArrow = true;
                break;
                default:
                    break;
        }
    }
}
