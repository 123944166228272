
@if(label){
    <intm-simple-label [for]="name" [disabled]="disabled" >{{label}}</intm-simple-label>
}
<textarea
    [placeholder]="placeholder"
    class="w-full py-12 px-16 rounded-8 font-helvetica-now-regular font-regular text-14 leading-20 -tracking-0-5 {{control && control.touched && control.invalid ? 'shadow-semantic-danger': 'shadow-stroke-low'}} {{ disabled ? 'opacity-25 cursor-not-allowed' : '' }} focus:outline-none focus:shadow-semantic-primary focus:shadow-semantic-primary placeholder:text-content-low"
    [ngClass]="{'h-84': size ==='normal', 'h-full': size === 'auto'}"
    [value]="value"
    style="resize:none;"
    [name]="name"
    [id]="id"
    [disabled]="disabled"
    (change)="onChanged($event)"
    rows="6"
></textarea>
@if(control && control.touched && control.invalid){
    <intm-message-helper-error [message]="errorMessage"></intm-message-helper-error>
}
