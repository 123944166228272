import { Component, Input } from '@angular/core';

@Component({
    selector: 'intm-text-mid-xs',
    template: `
        <div class="font-helvetica-now-medium font-medium text-14 leading-20 -tracking-0-4 m:text-12 m:leading-18 m:-tracking-0-3"
             [ngClass]="{
                    'text-content-high': color=='high',
                    'text-content-mid': color=='mid',
                    'text-content-low': color=='low',
                    'text-content-oncolor-high': color=='oncolor-high',
                    'text-content-oncolor-mid': color=='oncolor-mid',
                    'text-semantic-primary': color=='semantic-primary'
                 }"
        >
            <ng-content></ng-content>
        </div>
    `
})
export class TextMidXSComponent {
    @Input() color: 'high' | 'mid' | 'low' | 'oncolor-high' | 'oncolor-mid' | 'semantic-primary'= 'high';
}
