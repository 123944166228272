import { find } from "lodash";
import { IFlags } from "@app/interface/insurance.interface";
import { FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";


export const getRandomBackground  = (): string => {
    let background: string = '';
    const numberRandom: number = Math.floor(Math.random() * 5) + 1;
    background = `assets/images/backgrounds/background-${numberRandom}.jpg`;
    return background;
}

export const validateForm = (formGroup: FormGroup): void => {
    Object.keys(formGroup.controls).forEach( field =>
        {
            const control = formGroup.get(field);
            if (control instanceof FormControl)
                control.markAsTouched({ onlySelf: true });
            else if ( control instanceof FormGroup)
                validateForm(control);
        }
    );
}

export const getCurrentYear = (): number => {
    return new Date().getFullYear();
}

export const getDays = (): Array<{text: string, value: string}> => {
    let daysArray: Array<{text: string, value: string}> = [];
    for (let day = 1; day <= 31; day++) {
        if (day < 10) {
            daysArray.push({ text: '0' + day, value: '0' + day });
        } else {
            daysArray.push({ text: String(day), value: String(day) });
        }
    }
    return daysArray;
}

export const getMonths = (): Array<{text: string, value: string}>  => {
    let monthsArray: Array<{text: string, value: string}> = [];
    for (let month = 1; month <= 12; month++) {
        if (month < 10) {
            monthsArray.push({ text: '0' + month, value: '0' + month });
        } else {
            monthsArray.push({ text: String(month), value: String(month) });
        }
    }
    return monthsArray;
}

export const getYears = (numberYears: number): Array<{text: string, value: string}> => {
    const currentYear: number = new Date().getFullYear();
    let yearsArray: Array<{text: string, value: string}> = [];
    for (let year = currentYear; year > currentYear - numberYears; year--) {
        yearsArray.push({ text: String(year), value: String(year) });
    }
    return yearsArray;
}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return control.value.password === control.value.confirmPassword ? null : { equalTo: true };
}

export const findFlag = (flags: IFlags[] | undefined, key: string): IFlags | undefined => {
    let flag: IFlags | undefined = find(flags,
        (flag: IFlags) => {
            return flag.name == key;
        }
    );
    return flag ? flag : undefined;
}
