import { Component, Input } from '@angular/core';

@Component({
    selector: 'intm-text-high-s',
    template: `
        <div class="
            font-helvetica-now-regular font-regular text-28 leading-34 -tracking-1-4
            m:text-22 m:leading-30 m:-tracking-1-2"
             [ngClass]="{
             'text-content-high': color=='high',
             'text-content-mid': color=='mid',
             'text-content-low': color=='low',
             'text-content-oncolor-high': color=='oncolor-high',
             'text-content-oncolor-mid': color=='oncolor-mid'
             }"
        >
            <ng-content></ng-content>
        </div>
    `
})
export class TextHighSComponent {
    @Input() color: 'high' | 'mid' | 'low' | 'oncolor-high' | 'oncolor-mid'= 'high';
}
