import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TypographicTokensModule } from "@intm-ui/typographic-tokens/typographic-tokens.module";
import { Select } from "./select.component";
import { MessageHelperErrorComponent } from "@intm-ui/message-helper";


@NgModule({
    imports: [
        CommonModule,
        TypographicTokensModule,
        MessageHelperErrorComponent
    ],
    declarations: [
        Select
    ],
    exports: [
        Select
    ]
})
export class SelectModule {}