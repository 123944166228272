import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { IValidatorTexts, IValidatorTextsParams } from './message-helper.interface'



const REGEX_EMAIL =
    /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})$/;

@Injectable()
export class ValidationService {
    constructor() {}

    static getValidationErrorMessage(
        validatorName: string,
        ValidatorValue?: any,
        validatorTexts?: IValidatorTexts,
        validatorTextsParams?: IValidatorTextsParams
    ) {
        let configuration: any = {
            'required': validatorTexts?.required,
            'strUpper': validatorTexts?.strUpper,
            'invalidEmailAddress': validatorTexts?.invalidEmailAddress,
            'invalidNumber': validatorTexts?.invalidNumber,
            'wordNoSpacesValidator': validatorTexts?.wordNoSpacesValidator,
            'wordNoSpacesValidator2': validatorTexts?.wordNoSpacesValidator2,
            'invalidFormatDate': validatorTexts?.invalidFormatDate,
            'minDate': validatorTexts?.minDate,
            'maxDate': validatorTexts?.maxDate,
            'invalidDocumentLetter': validatorTexts?.invalidDocumentLetter,
            'mismoDNI': validatorTexts?.mismoDNI,
            'invalidDocumentFormat': validatorTexts?.invalidDocumentFormat,
            'invalidAge': validatorTexts?.invalidAge,
            'passwordInvalid': validatorTexts?.passwordInvalid,
            'invalidNumberPositive': validatorTexts?.invalidNumberPositive,
            'invalidNumberPhone': validatorTexts?.invalidNumberPhone,
            'invalirLowerCase': validatorTexts?.invalirLowerCase,
            'invalidDateRange': validatorTextsParams ? validatorTextsParams.invalidDateRange : '',
            'invalidRangeCurrentDate': validatorTexts?.invalidRangeCurrentDate,
            'passwordnotMatch': validatorTexts?.passwordnotMatch,
            'equalTo': validatorTexts?.passwordnotMatch,
            'poliza_incorrecta': validatorTexts?.invalidPolicy,
            'invalidCodiceFiscale': validatorTexts?.invalidCodiceFiscale,
            'invalidcartaciudadano': validatorTexts?.invalidcartaciudadano,
            'invalidFirstLastName': validatorTexts?.invalidFirstLastName,
            'invalidLettersAndNumbers': validatorTexts?.invalidLettersAndNumbers,
            'invalidCodicePostal': validatorTexts?.invalidCodicePostal,
            'maxlength': validatorTextsParams ? validatorTextsParams.maxlength : '',
            'minImporteAseguradoValor': validatorTextsParams ? validatorTextsParams.minImporteAseguradoValor : '',
            'maxImporteAseguradoValor': validatorTextsParams ? validatorTextsParams.maxImporteAseguradoValor : '',
            'minAsegurados': validatorTextsParams ? validatorTextsParams.minAsegurados : '',
            'maxAsegurados': validatorTextsParams ? validatorTextsParams.maxAsegurados : '',
            'duplicateBookinRef': validatorTexts?.duplicateBookinRef,
            'policyNumberNotFound': validatorTexts?.policyNumberNotFound,
            'documentNumberDuplicate': validatorTexts?.documentNumberDuplicate
        };
        return configuration[validatorName];
    }

    validateUpperLetters(numeroDocumento: string) {
        if (numeroDocumento && numeroDocumento != numeroDocumento.toUpperCase())
            return { strUpper: true };
        return null;
    }

    static emailValidator(control: FormControl): ValidationErrors | null {
        // RFC 2822 compliant regex
        const valor = control.value || '';
        if (!valor) {
            // Si no tiene valores no se valida el email
            // Debido a que este mismo al no comprobar el valor
            // forza a que sea requerido el field cuando puede que no lo sea
            return null;
        }

        if (valor.match(REGEX_EMAIL)) {
            return null;
        } else {
            return { invalidEmailAddress: true };
        }
    }
    static emailMultipleValidator(valor: string) {
        if (!valor) {
            // Si no tiene valores no se valida el email
            // Debido a que este mismo al no comprobar el valor
            // forza a que sea requerido el field cuando puede que no lo sea
            return null;
        }

        // Separamos los correos que nos envian usando el (punto y coma) como parametro
        const correos = valor.split(';');
        // Declaramos un array para los errores
        let errores: any = [];
        // Iteramos sobre cada uno de los correos
        correos.forEach((correo, index) => {
            // verificamos que el correo tenga un valor
            if (correo !== '') {
                // Si la validacion nos devuelve un null es porque fallo
                if (correo.match(REGEX_EMAIL) === null) {
                    // Agregamos el correo a nuestro listado de errores
                    errores.push({ err_correo: correo });
                }
            }
        });

        if (errores.length === 0) {
            // Retornamos null si no se tienen errores
            return null;
        } else {
            // Devolvemos el listado de errores
            return errores;
        }
    }

    static numberValidator(control: FormControl) {
        const valor = control.value || '';
        if (!valor) {
            return null;
        }
        let valorNew = String(valor);
        if (!valorNew.match(/(\D+)/g) || valor == '') {
            return null;
        } else {
            return { invalidNumber: true };
        }
    }

    static postalCodeValidator(control: FormControl) {
        const valor = control.value || '';
        if (!valor || valor == '') {
            return null;
        }
        let valorNew = String(valor);
        // Se obtiene el objeto match con las coincidencias después de validarlo con la ER
        let match = valorNew.match(/(-?\d[-\d\s]*)/);
        if (match) {
            // Si encontró coincidencias con match
            // Se valida que la longitud de la entrada sea igual a la primer coincidencia
            if (valor.length == match[0].length) {
                return null;
            }
        }
        return { invalidPostalCode: true };
    }

    // Solo una palabra sin espacios al prinicio o al final
    static wordNoSpacesValidator(control: FormControl) {
        const valor = control.value || '';
        if (valor.match(/^\b\w+\b$/) || valor == '') {
            return null;
        } else {
            return { wordNoSpacesValidator: true };
        }
    }
    // solo letras y espacios
    static lettersSpacesValidator(control: FormControl) {
        const valor = control.value || '';
        if (valor.match(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/) || valor == '') {
            return null;
        } else {
            return { onlyLettersValidation: true };
        }
    }

    static numberphoneValidator(control: FormControl): ValidationErrors | null {
        const valor = control.value || '';
        if (!valor) {
            return null;
        }

        var regExpPhone = /^[+]?\d*[0-9]\d*$/g;
        let newValor = String(valor);
        if (newValor.match(regExpPhone)) {
            return null;
        } else {
            return { invalidNumberPhone: true };
        }
    }

    static validateDNI(dni: string): any {

        const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
        const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;

        const str = dni.toString().toUpperCase();
        const letter = str.substr(-1);

        const charIndex = parseInt(dni.substr(0, 8)) % 23;

        if (!nifRexp.test(str)) {
            return { 'invalidDocumentFormat': true };
        }

        if (validChars.charAt(charIndex) !== letter) {
            return { 'invalidDocumentLetter': true };
        }

        return null

    }

    // Validación que representa mínimo 7 caracteres y una letra
    static validatePassword(control: FormControl): ValidationErrors | null {
        let passwordRexp = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+={}\[\]:;"'<>?,.\/\\~-]{7,}$/g;

        if (control.value && control.value.match(passwordRexp)) {
            return null;
        } else {
            return { passwordInvalid: true };
        }
    }

    static validateNIE(nie: string): any {
        if (!nie) {
            return null;
        }
        let validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
        let nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;

        let str = nie.toString().toUpperCase();

        var nie = str
            .replace(/^[X]/, '0')
            .replace(/^[Y]/, '1')
            .replace(/^[Z]/, '2');

        let letter = str.substr(-1);
        var charIndex = parseInt(nie.substr(0, 8)) % 23;

        if (!nieRexp.test(str)) return { invalidDocumentFormat: true };

        if (validChars.charAt(charIndex) !== letter)
            return { invalidDocumentLetter: true };

        return null;
    }

    static onlyNumber(control: FormControl) {
        let onlyNumberPositive = /^\d*[0-9]\d*$/;

        const valor = (control.value || '').toString();
        if (!valor) return null;

        if (valor.match(onlyNumberPositive)) {
            return null;
        } else {
            return { invalidNumberPositive: true };
        }
    }

    static onlyUpperCase(control: FormControl) {
        const onlyUpperCase = '.*[a-z].*';
        const valor = (control.value || '').toString();

        if (valor.match(onlyUpperCase)) {
            return { invalirLowerCase: true };
        } else {
            return null;
        }
    }

    /**
     * Valida el formato (YYYY-MM-DD o YYYY/MM/DD) de un string con estricto formato date
     * @param date
     */
    static dateFormatValidator(date: string) {
        let regexDate =
            /^([12]\d{3})((-(0[1-9]|1[0-2])-)|(\/(0[1-9]|1[0-2])\/))(0[1-9]|[12]\d{1}|3[01])$/g;

        const valor = (date || '').toString();
        if (valor.match(regexDate)) {
            return null;
        } else {
            return { invalidFormatDate: true };
        }
    }

    /**
     * Validacion para numeros decimales separado por comas
     * @param control
     */

    static decimalWithComma(control: FormControl) {
        let regexExp = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;
        const valor = (control.value || '').toString();
        if (!valor) {
            return null;
        }
        if (valor.match(regexExp)) {
            return null;
        } else {
            return { invalidNumberWithPoint: true };
        }
    }

    static decimalCommaAndPoint(control: FormControl) {
        const value = (control.value || '').toString();
        let regexExp = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
        if (!value) return null;
        if (value.match(regexExp)) return null;
        else return { invalidDecimal: true };
    }

    static firstLastName(control: FormControl) {
        const regex_ex = /^[a-zA-Z-ÑñÁáÉéÍíÓóÜúçÇ ]*$/;

        const value = (control.value || "").toString();
        if (value.match(regex_ex)) {
            return null;
        } else {
            return { "invalidFirstLastName": true };
        }
    }

    static onlyLettersAndNumbers(control: FormControl) {
        const regex_ex = /^[a-zA-ZÑñÁáÉéÍíÓóÜúÇç0-9 ]*$/;

        const value = (control.value || "").toString();
        if(value.match(regex_ex)) {
            return null;
        } else {
            return { "invalidLettersAndNumbers": true };
        }
    }

    static codicePostal(control: FormControl){
        const regex_ex = /^[a-zA-Z0-9\- ]*$/;
        const value = (control.value || "").toString();
        if(value.match(regex_ex)) {
            return null;
        } else {
            return { "invalidCodicePostal": true };
        }
    }
    static validatePolicyNumber(control: FormControl){
        if(control.value !== '') {
            return null;
        } else {
            return { "policyNumberNotFound": true };
        }
    }

    static codiceFiscale (value: any){
        const regex = /^.[A-Z0-9]{15,15}$/;
        if(regex.test(value)){
            return null;
        } else {
            return { "invalidCodiceFiscale" :true};
        }
    }

    static cartaCiudadana(value:any) {
        if (value && value !== '') {
            const valorArray: any = value.split("");
            let sum: number = 0;
            let secondDigit: boolean = false;
            if (valorArray.length !== 12) {
                return { "invalidcartaciudadano" :true};
            }
            for(let i = valorArray.length - 1; i >=0; --i) {
                let valor = ValidationService.GetNumberFromCharPortuguese(valorArray[i]);
                if (secondDigit) {
                    valor *= 2;
                    if (valor > 9) {
                        valor -= 9;
                    }
                }
                sum += valor;
                secondDigit = !secondDigit;
            }

            if (sum % 10) {
                return { "invalidcartaciudadano" :true};
            } else {
                return null;
            }
        }
        return null;
    }

    static GetNumberFromCharPortuguese(letter:string) {
        switch (letter) {
            case '0':
            return 0;
            case '1':
            return 1;
            case '2':
            return 2;
            case '3':
            return 3;
            case '4':
            return 4;
            case '5':
            return 5;
            case '6':
            return 6;
            case '7':
            return 7;
            case '8':
            return 8;
            case '9':
            return 9;
            case 'A':
            return 10;
            case 'B':
            return 11;
            case 'C':
            return 12;
            case 'D':
            return 13;
            case 'E':
            return 14;
            case 'F':
            return 15;
            case 'G':
            return 16;
            case 'H':
            return 17;
            case 'I':
            return 18;
            case 'J':
            return 19;
            case 'K':
            return 20;
            case 'L':
            return 21;
            case 'M':
            return 22;
            case 'N':
            return 23;
            case 'O':
            return 24;
            case 'P':
            return 25;
            case 'Q':
            return 26;
            case 'R':
            return 27;
            case 'S':
            return 28;
            case 'T':
            return 29;
            case 'U':
            return 30;
            case 'V':
            return 31;
            case 'W':
            return 32;
            case 'X':
            return 33;
            case 'Y':
            return 34;
            default:
            return 35;
        }
    }
}
