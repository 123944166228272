import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TypographicTokensModule } from "@intm-ui/typographic-tokens/typographic-tokens.module";

@Component({
    selector: 'intm-documents-list',
    templateUrl: './documents-list.component.html',
    standalone: true,
    imports: [TypographicTokensModule]
})
export class DocumentsList {
    @Input() documents: IntmFile[] = [];
    @Input() textRemove: string = '';
    @Input() textDelete: string = '';
    @Output() removeFile = new EventEmitter<any>();

    constructor() {}

    remove(file: File, index: number) {
        this.removeFile.emit(
            {
                file: file,
                index: index
            }
        );
    }
}

export interface IntmFile {
    file: File,
    progress: number,
    id: number,
    status: 'pending' | 'error' | 'ok' | 'delete',
    error?: string
}