import { Component, Input } from '@angular/core';

@Component({
    selector: 'intm-text-low-xs',
    template: `
        <div class="font-helvetica-now-regular font-regular text-12 leading-18 -tracking-0-5"
             [ngClass]="{
             'text-content-high': color=='high',
             'text-content-mid': color=='mid',
             'text-content-low': color=='low',
             'text-content-oncolor-high': color=='oncolor-high',
             'text-content-oncolor-mid': color=='oncolor-mid',
             'text-semantic-success': color=='success',
             'text-semantic-danger': color=='error', 
             'text-semantic-warning': color=='warning', 
             'text-semantic-primary': color=='info'
             }"
        >
            <ng-content></ng-content>
        </div>
    `
})
export class TextLowXSComponent {
    @Input() color: 'high' | 'mid' | 'low' | 'oncolor-high' | 'oncolor-mid' | 'success' | 'error' | 'warning' | 'info' = 'high';
}
