import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestManagerService {
  private requests: { [url: string]: Subject<any> } = {};

  addRequest(url: string): boolean {
    if (this.requests[url]) {
      return false; // Indica que la solicitud ya está en curso.
    }
    this.requests[url] = new Subject<any>();
    return true;
  }

  getRequest(url: string) {
    return this.requests[url] ? this.requests[url].asObservable() : null;
  }

  resolveRequest(url: string, response: any) {
    if (this.requests[url]) {
      this.requests[url].next(response);
      this.requests[url].complete();
      delete this.requests[url];
    }
  }

  rejectRequest(url: string, error: any) {
    if (this.requests[url]) {
      this.requests[url].error(error);
      delete this.requests[url];
    }
  }

  removeRequest(url: string) {
    if (this.requests[url]) {
      delete this.requests[url];
    }
  }
}
