<div class="relative flex"
    [ngClass]="{
        'justify-center': isCenterPositionArrow,
        'justify-end': isLeftPositionArrow,
        'justify-start': isRightPositionArrow
    }"
>
    <div (click)="toogleIsVisible()">
        <ng-content></ng-content>
    </div>
    @if(isVisible()){
        <div class="
                absolute w-max max-w-[320px] flex flex-col mb-8 mt-8 z-50 p-8 bg-content-high text-white  rounded-8 {{ isBottomPosition ? 'top-full':'bottom-full'}}
                xs:fixed xs:w-full xs:max-w-[640px] xs:flex-row xs:items-end xs:justify-center xs:m-0 xs:z-[55] xs:p-0 xs:bg-overlay xs:text-content-high xs:inset-0 xs:rounded-0
            "
            [ngClass]="{
                'ml-[-8px]': isRightPositionArrow,
                'mr-[-8px]': isLeftPositionArrow,
                'xs:mx-[0]': true
            }"
        >
            @if(isBottomPosition){
                <div class="absolute w-0 h-0 top-[-4px] transform -translate-x-1/2 xs:hidden"
                    [ngClass]="{
                        'left-[48%]': isCenterPositionArrow,
                        'left-[94%]': isLeftPositionArrow,
                        'left-[4%]': isRightPositionArrow
                    }"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="4" viewBox="0 0 9 4" fill="none">
                        <path d="M4.5 0L8.5 4H0.5L4.5 0Z" fill="#131D34"/>
                    </svg>
                </div>
            }

            <div class="xs:relative xs:bg-base xs:rounded-t-16 xs:shadow-lg xs:px-16 xs:py-24 xs:w-full xs:max-h-[90%] xs:h-fit xs:z-[55]">
                <div class="grid grid-cols-[1fr_16px] gap-4">
                    <div class="flex flex-col">
                        @if(title){
                            <div class="font-helvetica-now-regular mb-8 font-regular text-16 leading-22 -tracking-0-5 m:-tracking-0-6">
                                {{title}}
                            </div>
                        }
                        @if(textContent){
                            <div class="w-fit font-helvetica-now-regular font-regular text-12 leading-18 -tracking-0-5">
                                {{ textContent }}
                            </div>
                        }
                        <ng-content select="[html-content]"></ng-content>
                    </div>
                    <div class="w-16 h-16">
                        <button (click)="toogleIsVisible()">
                            <svg class="xs:hidden" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.6668 3.33334L3.3335 12.6667M3.3335 3.33334L12.6668 12.6667" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
                            </svg>
                            <svg class="hide xs:block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19 5L5 19M5 5L19 19" stroke="#131D34" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            @if(isTopPosition){
                <div class="absolute w-0 h-0 bottom-[0px] transform -translate-x-1/2 xs:hidden "
                     [ngClass]="{
                        'left-[48%]': isCenterPositionArrow,
                        'left-[94%]': isLeftPositionArrow,
                        'left-[4%]': isRightPositionArrow
                    }">
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="4" viewBox="0 0 9 4" fill="none">
                        <path d="M4.5 4L0.5 0H8.5L4.5 4Z" fill="#131D34"/>
                    </svg>
                </div>
            }
        </div>
    }
</div>
