import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TypographicTokensModule } from '@intm-ui/typographic-tokens/typographic-tokens.module';

@Component({
  selector: 'intm-tag',
  standalone: true,
  imports: [
    TypographicTokensModule,
    CommonModule
  ],
  templateUrl: './tag.component.html'
})
export class TagComponent {
  @Input('type') public type: 'success' | 'error' | 'info' | 'warning' | 'informative' | 'alert' | 'gray' = 'info';
}
