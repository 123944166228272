import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
    name: 'parseName'
})
export class ParseNamePipe implements PipeTransform {
    constructor() {}

    transform(name: string): string {
        if (this.proveNaN(name.split('-'))) {
            let nameParse: string = name;
            let nameSplit: string[] = name.split('.');
            let extension: string = nameSplit[nameSplit.length - 1];
            let index: number = name.indexOf('-') + 1;
            nameParse = nameParse.substring(index);
            index = nameParse.indexOf('-') + 1;
            nameParse = nameParse.substring(index);
            index = nameParse.indexOf('-') + 1;
            nameParse = nameParse.substring(index);
            index = nameParse.indexOf('-') + 1;
            nameParse = nameParse.substring(index);
            index = nameParse.indexOf('-') + 1;

            let nameTemp: string[] = nameParse.split('-');
            nameParse = nameTemp[0];
            return `${nameParse}`;
        } else {
            return name;
        }
    }

    private proveNaN(nombre: string[]): boolean {
        if (!(Number(nombre[0]) === Number(nombre[0]))) {
            return false;
        }
        if (!(Number(nombre[1]) === Number(nombre[1]))) {
            return false;
        }
        if (!(Number(nombre[2]) === Number(nombre[2]))) {
            return false;
        }
        if (!(Number(nombre[3]) === Number(nombre[3]))) {
            return false;
        }
        return true;
    }
}