import { findFlag } from "src/app/utils";
import { Pipe, PipeTransform } from "@angular/core";
import { IFlags } from "@app/interface/insurance.interface";


@Pipe(
    {
        name: 'getFlag'
    }
)
export class GetFlagPipe implements PipeTransform {
    constructor() {}

    transform(flags: IFlags[], key: string): any {
        let flag: IFlags | undefined = findFlag(flags, key);
        return flag ? flag.value : false;
    }
}
