import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
    name: 'bold',
    standalone: true
})
export class TextBoldPipe implements PipeTransform {
    constructor() {}

    transform(value: string, search: string) {
        if (search) {
            const regExpSearch = new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi');
            const resultText: string = value.replace(regExpSearch, match => `<span class="font-helvetica-now-regular text-14 font-medium">${match}</span>`);
            return resultText;
        }
        return value;
    }
}
