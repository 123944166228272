<div
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
>
    <label for="{{id}}" class="flex flex-col items-center justify-center p-24 border border-dashed rounded-8 {{control && (control.invalid && control.touched) ? 'border-semantic-danger' : 'border-overlay'}} hover:cursor-pointer hover:opacity-75"
        
    >
        <input id="{{id}}" type="file" class="hidden" (change)="onChanged($event)"/>
        <div class="flex items-center gap-8 xs:flex-col">
            <img class="s:hidden" src="assets/images/icons/file/file.svg"/>
            <img class="hidden s:block" src="assets/images/icons/file/file-24.svg"/>
            <div class="flex xs:flex-col">
                <intm-text-low-s [color]="'mid'" class="xs:text-center">
                    {{ textContent }}
                </intm-text-low-s>
                @if (textLimit && textLimit != '') {
                    <span class="h-16 xs:hidden">&nbsp;</span>
                    <intm-text-low-s [color]="'mid'" class="xs:text-center">
                        {{ textLimit }}
                    </intm-text-low-s>
                }
            </div>
        </div>
    </label>
    @if(control && control.touched && control.invalid){
        <intm-message-helper-error [control]="control"></intm-message-helper-error>
    }
</div>
