@if(type){
    @if(label){
        <intm-simple-label [for]="name" [disabled]="disabled" >{{label}}</intm-simple-label>
    }
    <input
        [placeholder]="placeholder"
        class="w-full px-16 rounded-8 font-helvetica-now-regular font-regular text-14 xs:text-16 leading-20 -tracking-0-5 {{control && control.touched && control.invalid ? 'focus:outline-none shadow-semantic-danger': 'shadow-stroke-low focus:outline-none focus:shadow-semantic-primary'}} {{ disabled ? 'opacity-25 cursor-not-allowed' : '' }} placeholder:text-content-low"
        [ngClass]="{'h-40 xs:h-48': size ==='normal', 'h-48': size === 'large'}"
        [type]="type"
        [name]="name"
        [id]="id"
        [value]="value"
        [disabled]="disabled"
        (change)="onChanged($event)"
        (keyup)="onChanged($event)"
        tabindex="{{index}}"
    />
    @if(control && control.touched && control.invalid){
        <intm-message-helper-error [control]="control"></intm-message-helper-error>
    }
}

