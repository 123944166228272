import { Component, Input } from '@angular/core';

@Component({
    selector: 'intm-text-high-xs',
    template: `
        <div class="
            font-helvetica-now-regular font-regular text-24 leading-30 -tracking-1-2
            m:text-20 m:leading-28 m:-tracking-1"
             [ngClass]="{
             'text-content-high': color=='high',
             'text-content-mid': color=='mid',
             'text-content-low': color=='low',
             'text-content-oncolor-high': color=='oncolor-high',
             'text-content-oncolor-mid': color=='oncolor-mid',
             'text-semantic-primary': color=='primary'
             }"
        >
            <ng-content></ng-content>
        </div>
    `
})
export class TextHighXSComponent {
    @Input() color: 'high' | 'mid' | 'low' | 'oncolor-high' | 'oncolor-mid' | 'primary'= 'high';
}
