interface options {
    text: string,
    value: any,
    disable?: boolean
}
import {
    Component, ElementRef, ViewChild,
    Input,
    ViewEncapsulation,
    forwardRef,
    Injector,
    inject,
    OnInit,
    Output,
    EventEmitter, ViewChildren, QueryList
} from "@angular/core";
import {
    ControlContainer,
    ControlValueAccessor,
    FormGroup,
    FormControl,
    FormControlDirective,
    FormControlName,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel,

} from "@angular/forms";

@Component({
    selector: 'intm-select',
    templateUrl: './select.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => Select),
            multi: true
        }
    ]
})
export class Select implements OnInit, ControlValueAccessor {
    showSelect: boolean                 = false;
    typedValue: string                = '';
    @Input() value: any                 = undefined;
    @Input() placeholder: string        = '';
    @Input() options: Array<options>    = [];
    @Input() disabled: true | false     = false;
    @Output() change                    = new EventEmitter<{text: string, value: any}>();
    @Input() class: string              = '';

    @ViewChild('dropdownContainer') dropdownContainer: ElementRef | undefined;
    @ViewChild('selectList') selectList!: ElementRef;
    @ViewChildren('listItem') listItems!: QueryList<ElementRef>;


    public control!: FormControl;
    injector = inject(Injector);

    ngOnInit(): void {
        const ngControl = this.injector.get(NgControl, null, { self: true, optional: true });
        if (ngControl instanceof NgModel) {
        this.control = ngControl.control;

        ngControl.control.valueChanges.subscribe((value) => {
            if (ngControl.model !== value || ngControl.viewModel !== value) {
            ngControl.viewToModelUpdate(value);
            }
        });

        } else if (ngControl instanceof FormControlDirective) {
            this.control = ngControl.control;

        } else if (ngControl instanceof FormControlName) {
            const container = this.injector.get(ControlContainer).control as FormGroup;
            this.control = container.controls[ngControl.name ? ngControl.name: ''] as FormControl;

        } else {
            this.control = new FormControl();
        }

        if (this.value != undefined) {
            this.control.patchValue(this.value);
        }
    }

    toggle() {
        if (this.control && !this.control.disabled) {
            this.showSelect = !this.showSelect;
            if (this.showSelect && this.value) {
                setTimeout(() => {
                    if (this.listItems.toArray()) {
                        let index: number = this.options.findIndex((option: {value: string, text: string}) => {
                            return option.text == this.value.text || option.value == this.value.value
                        })
                        if (index != -1) {
                            this.scrollElement(index);
                        }
                    }
                }, 0)
            }
        }
    }

    closeOnBlur(event: FocusEvent) {
        if (this.dropdownContainer && !this.dropdownContainer.nativeElement.contains(event.relatedTarget)) {
            this.close();
        }
    }

    private close () {
        this.showSelect = false;
        if(this.control) this.control.markAsTouched();
    }

    public regOnChange = (_: any) => {};

    public regOnTouched = (_: any) => {};

    registerOnChange(fn: any): void {
        this.regOnChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.regOnTouched = fn;
    }

    writeValue(value: any) {
        if (value) {
            this.value = value;
        }
    }

    onChanged(value: options) {
        this.value = value;
        this.regOnChange(this.value);
        this.change.emit(this.value);
        this.close();
    }

    public clearValue() {
        if(this.value == undefined) return;
        this.value = undefined;
        this.regOnChange(this.value);
        this.change.emit(this.value);
        this.close();
    }

    typingValue(event: KeyboardEvent): void {
        if (event && event.key) {
            this.typedValue += event.key;
        }
    }

    searchValueTyped(): void {
        setTimeout(() => {
            let index: number = this.options.findIndex((option: {value: string, text: string}) => {
                return option.text == this.typedValue || option.value == this.typedValue
            });

            if (index === -1) {
                this.typedValue = '';
            } else {
                this.scrollElement(index);
                this.onChanged(this.options[index]);
            }
        }, 700)
    }

    scrollElement(index: number): void{
        const selectedElement = this.listItems.toArray()[index].nativeElement;
        if (selectedElement) {
            const selectListEl = this.selectList.nativeElement;
            const scrollOffset = selectedElement.offsetTop - selectListEl.offsetTop;
            selectListEl.scrollTo({ top: scrollOffset, behavior: 'auto' });
        }
    }
}
