import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private modals: Map<string, BehaviorSubject<'open' | 'close'>> | undefined = new Map();

    register(id: string): void {
        if (this.modals && !this.modals.has(id)) {
            this.modals.set(id, new BehaviorSubject<'open' | 'close'>('close'));
        }
    }

    unregister(id: string): void {
        if (this.modals) {
            this.modals.delete(id);
        }
    }

    open(id: string): void {
        if (this.modals) {
            const modal = this.modals.get(id);
            if (modal) {
                modal.next('open');
            }
        }
    }

    close(id: string): void {
        if (this.modals) {
            const modal = this.modals.get(id);
            if (modal) {
                modal.next('close');
            }
        }
    }

    watch(id: string): Observable<'open' | 'close'> {
        this.register(id);
        const modal = this.modals?.get(id);
        if (modal) {
            return modal.asObservable();
        }
        throw new Error('Modal no existe');
    }
}
