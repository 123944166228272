import { Component, Input, OnInit } from "@angular/core";
import { Insurance, InsuranceEmpty } from "@app/interface/insurance.interface";
import { TagComponent } from "@intm-ui/tags/tag.component";
import { TypographicTokensModule } from "@intm-ui/typographic-tokens/typographic-tokens.module";
import { TranslateModule } from "@ngx-translate/core";


@Component({
    selector: 'insurance-status',
    template:  `
        <div class="flex">
            <intm-tag [type]="status">
                <intm-text-low-xs>Estado:</intm-text-low-xs>&nbsp;
                <intm-text-low-xs [color]="status">{{ txtTranslate | translate }}</intm-text-low-xs>
            </intm-tag>
        </div>
    `,
    standalone: true,
    imports: [
        TagComponent,
        TypographicTokensModule,
        TranslateModule
    ]
})
export class InsuranceStatusComponent implements OnInit {
    @Input() insurance: Insurance = InsuranceEmpty;
    currentDate: Date = new Date();
    status: string = 'info';
    txtTranslate: string = 'seguros.estado_vencido';


    ngOnInit(): void {
        if(this.insurance.apiVersion){
            const startDate: Date = new Date(this.insurance.effectDate ? this.insurance.effectDate : '');
            const endDate: Date = new Date(this.insurance.unsuscribeDate ? this.insurance.unsuscribeDate : '');
            if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
                if (this.currentDate < startDate) {
                    this.status = 'success';
                    this.txtTranslate = 'seguros.estado_activo';
                } else if ((this.currentDate >= startDate) && (this.currentDate <= endDate)) {
                    this.status = 'success';
                    this.txtTranslate = 'seguros.estado_en_vigor';
                } else if (this.currentDate > endDate) {
                    this.status = 'error';
                    this.txtTranslate = 'seguros.estado_vencido';
                }
            }
        }else{
            this.status = "warning";
            this.txtTranslate = "seguros.estado_verificando";
        }
    }
}