import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";


@Component({
    selector: 'intm-link',
    template: `
        @if (path != "") {
            <div
                class="font-helvetica-now-regular font-regular text-12 leading-18 -tracking-0-5 text-semantic-primary hover:opacity-75 hover:cursor-pointer"
                [routerLink]="[path]"
            >
                {{text}}
            </div>
        }
        @else {
            <div
                class="font-helvetica-now-regular font-regular text-12 leading-18 -tracking-0-5 text-semantic-primary hover:opacity-75 hover:cursor-pointer"
                (click)="clickEvent()"
            >
                <ng-content></ng-content>
            </div>
        }
        
    `,
    standalone: true,
    imports: [
        RouterModule,
        CommonModule
    ]
})
export class Link {
    @Input() path: string = '';
    @Input() text: string = '';
    @Output() click = new EventEmitter<any>();

    constructor() {}

    clickEvent() {
        this.click.emit();
    }
}