<div *ngIf="display" class="fixed inset-0 z-[99] bg-overlay fade-in">
    <div
        class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex transition-all modal-open:duration-500"
    >
        <div
            class="relative mx-auto {{ size == 'small' ?  'w-[464px] min-w-[464px] max-[464px]' : 'w-auto max-w-2xl min-w-[550px]'}}  xs:h-dvh xs:w-[100%] xs:max-w-[100%] xs:min-w-[100%] xs:flex xs:items-end"
        >
            <div
                class="border-0 rounded-16 xs:rounded-b-none shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none xs:max-h-[95dvh]"
            >
                <div
                    class="flex items-center justify-between  gap-16 pt-24 pr-24 pb-16 pl-24 xs:pt-24 xs:pl-16 xs:pr-16 xs:pb-16"
                >
                    <ng-content select="intm-modal-header"></ng-content>
                    <img
                        src="assets/images/icons/close.svg"
                        alt="close"
                        class="hover:cursor-pointer"
                        (click)="closeModal()"
                    />
                </div>
                <div class="relative pl-24 pr-24 flex-auto max-h-[688px] overflow-y-auto xs:pl-16 xs:pr-16">
                    <ng-content select="intm-modal-body"></ng-content>
                </div>
                <div class="pt-16 pr-24 pb-24 pl-24 xs:p-16">
                    <ng-content select="intm-modal-footer"></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>
