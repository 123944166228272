import {Pipe, PipeTransform} from "@angular/core";
import { LANG_DEFINITION } from "../utils/const/lang-const";
import { LangService } from "@app/service/utils";


@Pipe({ name: "isLangES"})
export class isLangESPiPe implements PipeTransform {
    transform(): any {
        if (LangService.getLang() === LANG_DEFINITION.es) {
            return true;
        }
        return false;
    }
}

@Pipe({ name: "isLangIT"})
export class isLangITPiPe implements PipeTransform {
    transform(): any {
        if (LangService.getLang() === LANG_DEFINITION.it) {
            return true;
        }
        return false;
    }
}

@Pipe({ name: "isLangPT"})
export class isLangPTPiPe implements PipeTransform {
    transform(): any {
        if (LangService.getLang() === LANG_DEFINITION.pt) {
            return true;
        }
        return false;
    }
}
