import { Component, Input } from '@angular/core';
import { AbstractControl} from '@angular/forms';
import { ValidationService } from "../";
import { TranslateService } from '@ngx-translate/core';
import { IValidatorTexts, IValidatorTextsParams } from '../message-helper.interface';

@Component({
    standalone: true,
    selector: 'intm-message-helper-error',
    template:`
        @if (errorMessage !== null) {
            <div class="expand-height mt-4 font-helvetica-now-regular font-regular text-12 leading-18 -tracking-0-5 text-semantic-danger">
                {{ errorMessage}}
            </div>
        }
    `
})
export class MessageHelperErrorComponent {
    @Input() control!: AbstractControl<any>;
    @Input() message: string = ""
    @Input() formSubmitted!: boolean;

    validatorsText: IValidatorTexts;
    ValidatorsTextParams: IValidatorTextsParams;

    constructor(
        private translate: TranslateService
    ){
        this.translate.get([
            'validacion.required',
            'validacion.strupper',
            'validacion.invalidemailaddress',
            'validacion.invalidnumber',
            'validacion.wordnospacesvalidator',
            'validacion.wordnospacesvalidator2',
            'validacion.invalidaformatdate',
            'validacion.mindate',
            'validacion.maxdate',
            'validacion.invaliddocumentletter',
            'validacion.mismodni',
            'validacion.invaliddocumentformat',
            'validacion.invalidage',
            'validacion.passwordinvalid',
            'validacion.invalidnumberpositive',
            'validacion.invalidnumberphone',
            'validacion.invalirlowercase',
            'validacion.invalidrangecurrentdate',
            'validacion.passwordnotmatch',
            'validacion.invalidcodicefiscale',
            'validacion.invalidcartaciudadano',
            'validacion.invalidfirstlastname',
            'validacion.invalidlettersandnumbers',
            'validacion.invalidcodicepostal',
            'validacion.duplicate_bookinreference',
            'validacion.poliza_no_existe',
            'validacion.documento_duplicado'
        ]).subscribe((texts:any)=>{
            this.validatorsText = {
                required: texts['validacion.required'],
                strUpper: texts['validacion.strupper'],
                invalidEmailAddress: texts['validacion.invalidemailaddress'],
                invalidNumber: texts['validacion.invalidnumber'],
                wordNoSpacesValidator: texts['validacion.wordnospacesvalidator'],
                wordNoSpacesValidator2: texts['validacion.wordnospacesvalidator2'],
                invalidFormatDate: texts['validacion.invalidaformatdate'],
                minDate: texts['validacion.mindate'],
                maxDate: texts['validacion.maxdate'],
                invalidDocumentLetter: texts['validacion.invaliddocumentletter'],
                mismoDNI: texts['validacion.mismodni'],
                invalidDocumentFormat: texts['validacion.invaliddocumentformat'],
                invalidAge: texts['validacion.invalidage'],
                passwordInvalid: texts['validacion.passwordinvalid'],
                invalidNumberPositive: texts['validacion.invalidnumberpositive'],
                invalidNumberPhone: texts['validacion.invalidnumberphone'],
                invalirLowerCase: texts['validacion.invalirlowercase'],
                invalidRangeCurrentDate: texts['validacion.invalidrangecurrentdate'],
                passwordnotMatch: texts['validacion.passwordnotmatch'],
                invalidCodiceFiscale: texts['validacion.invalidcodicefiscale'],
                invalidcartaciudadano: texts['validacion.invalidcartaciudadano'],
                invalidFirstLastName: texts['validacion.invalidfirstlastname'],
                invalidLettersAndNumbers: texts['validacion.invalidlettersandnumbers'],
                invalidCodicePostal: texts['validacion.invalidcodicepostal'],
                duplicateBookinRef: texts['validacion.duplicate_bookinreference'],
                policyNumberNotFound: texts['validacion.poliza_no_existe'],
                documentNumberDuplicate: texts['validacion.documento_duplicado']
            }
        })

    }

    get errorMessage() {
        if (this.control) {
            for (let propertyName in this.control.errors) {
                if (this.control.errors.hasOwnProperty(propertyName) && (this.control.touched || this.control.dirty || this.formSubmitted)) {
                    this.getTextsvalidationParams(propertyName, this.control.errors[propertyName]);
                    return ValidationService.getValidationErrorMessage(propertyName, this.control.errors[propertyName], this.validatorsText, this.ValidatorsTextParams);
                }
            }
        }else if(this.message !== ''){
            return this.message
        }   
        return null;
    }

    getTextsvalidationParams(propertyName:any, validatorValue:any) {
        switch(propertyName) {
            case 'maxlength':
                this.translate.get('validacion.maxlength', {length: validatorValue ? validatorValue.requiredLength : '' })
                    .subscribe(
                        text => {
                            this.ValidatorsTextParams = {
                                maxlength: text
                            }
                        }
                    );
                break;
            case 'minImporteAseguradoValor':
                this.translate.get('validacion.minimporteaseguradovalor', {value: validatorValue ? validatorValue : '' })
                    .subscribe(
                        text => {
                            this.ValidatorsTextParams = {
                                minImporteAseguradoValor: text
                            }
                        }
                    );
                break;
            case 'maxImporteAseguradoValor':
                this.translate.get('validacion.maximporteaseguradovalor', {value: validatorValue ? validatorValue: '' })
                    .subscribe(
                        text => {
                            this.ValidatorsTextParams = {
                                maxImporteAseguradoValor: text
                            }
                        }
                    );
                break;
            case 'minAsegurados':
                this.translate.get('validacion.minasegurados', {value: validatorValue ? validatorValue : '' })
                    .subscribe(
                        text => {
                            this.ValidatorsTextParams = {
                                minAsegurados: text
                            }
                        }
                    );
                break;
            case 'maxAsegurados':
                this.translate.get('validacion.maxasegurados', {value: validatorValue ? validatorValue : '' })
                    .subscribe(text => {
                            this.ValidatorsTextParams = {
                                maxAsegurados: text
                            };
                        }
                    );
                break;
            case 'invalidDateRange':
                this.translate.get('validacion.invaliddaterange', {value: validatorValue ? validatorValue : '' })
                    .subscribe(text => {
                            this.ValidatorsTextParams = {
                                invalidDateRange: text
                            };
                        }
                    );
                break;
            default:
                break;
        }
    }
}
