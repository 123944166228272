@if(type){
    @if(label){
        <intm-simple-label [for]="name" [disabled]="disabled" >{{label}}</intm-simple-label>
    }
    <div class="flex items-center" style="margin-right: 17px;">
        <input
            [placeholder]="placeholder"
            class="w-full flex-1 px-16 rounded-8 font-helvetica-now-regular font-regular text-14 xs:text-16 leading-20 -tracking-0-5 {{control && control.touched && control.invalid ? 'focus:outline-none shadow-semantic-danger': 'shadow-stroke-low focus:outline-none focus:shadow-semantic-primary'}} {{ disabled ? 'opacity-25 cursor-not-allowed' : '' }} {{class}} placeholder:text-content-low"
            [ngClass]="{'h-40 xs:h-48': size ==='normal', 'h-48': size === 'large'}"
            [type]="showPassword() ? 'text' : 'password'"
            [name]="name"
            [id]="id"
            [value]="value"
            [disabled]="disabled"
            (change)="onChanged($event)"
            (keyup)="onChanged($event)"
            tabindex="{{index}}"
        />
        <button type="button" (click)="toggleShowPassword()" style="margin-left: -33px; height: 16px; width: 16px;" class="{{ disabled ? 'opacity-25' : '' }} focus:outline-none" [disabled]="disabled" >
            @if(showPassword()){
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M9.02939 6.64138C9.38857 6.98699 9.61134 7.46818 9.61134 8.00033C9.61134 9.0523 8.74078 9.90509 7.6669 9.90509C7.14244 9.90509 6.66648 9.7017 6.31673 9.37103M13.1112 2.66699L2.22233 13.3337M9.61144 3.80984C9.08709 3.42888 8.44477 3.42888 7.66699 3.42888C4.66011 3.42888 0.666992 8.00031 0.666992 8.00031L3.00033 9.97502M4.33835 11.0479C5.42508 11.8262 6.60728 12.5717 7.66699 12.5717C10.6739 12.5717 14.667 8.00031 14.667 8.00031C14.667 8.00031 12.9068 5.91178 10.8438 4.57174" stroke="#131D34" stroke-opacity="0.45" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }@else{
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M9.68654 7.7762C9.68654 8.89152 8.78239 9.79566 7.66708 9.79566C6.55176 9.79566 5.64762 8.89152 5.64762 7.7762C5.64762 6.66088 6.55176 5.75674 7.66708 5.75674C8.78239 5.75674 9.68654 6.66088 9.68654 7.7762Z" stroke="#131D34" stroke-opacity="0.45" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.4616 7.29574C14.735 7.56276 14.735 7.98955 14.4616 8.25657C13.2851 9.40584 10.1516 12.219 7.66663 12.219C5.18165 12.219 2.04812 9.40584 0.87163 8.25657C0.598291 7.98955 0.598291 7.56276 0.87163 7.29574C2.04812 6.14647 5.18165 3.33334 7.66663 3.33334C10.1516 3.33334 13.2851 6.14647 14.4616 7.29574Z" stroke="#131D34" stroke-opacity="0.45" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
        </button>
    </div>
    @if(control && control.touched && control.invalid){
        <intm-message-helper-error [control]="control"></intm-message-helper-error>
    }
}
