import { Injectable } from "@angular/core";
import { of, throwError, Observable} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpErrorResponse,
} from '@angular/common/http';
import { BoxApiService } from "./box.api.service";


const basePath = 'https://upload.box.com/api/2.0/';


@Injectable(
    { providedIn: 'root' }
)
export class BoxService {
    bearer: string;
    headers: HttpHeaders;
    options: HttpParams;
    
    constructor(
        private http: HttpClient,
        private boxApi: BoxApiService
    ) {
        
        this.getToken();
    }

    getBearer() {
        this.headers = new HttpHeaders()
            .set('Authorization', `Bearer ${this.bearer}`)
            .set('x-api-key', '1f887171ef9adb213ab93b5a0129043c0a7e5b0c99324882496c2c0ad19e893c');
        return this.headers;
    }

    getToken() {
        return new Promise(
            (resolve, reject) => {
                this.boxApi.getToken().subscribe(
                    (value: { token: string }) => {
                        this.setToken(value.token);
                        resolve(value.token);
                    }
                );
            }
        );
    }

    private setToken(token: string): void {
        this.bearer = token;
    }


    get(path: string, params?: any, replaceUrl?: boolean): Observable<any> {
        this.getBearer();
        let httpParams: HttpParams = new HttpParams();
        let url: string;
        if (replaceUrl) {
            url = path
        } else {
            url = this.makeUrl(path);
        }
        if (params) {
            const dicKeys = Object.keys(params);
            for (let row of dicKeys) {
                httpParams = httpParams.set(row, params[row]);
            }
        }
        return this.http.get(url, {headers: this.headers, params: httpParams})
            .pipe(
                map((resp: any) => {
                    return resp;
                }),
                catchError((this.handleError.bind(this)))
            )
    }
    private handleError(error: any): Observable<never> {
        return throwError(() => new Error(error));
    }

    private makeUrl(path: string) {
        if (path[0] === "/") {
            path = path.substring(1, path.length);
        }
        return basePath + path;
    }

    delete(path: string): Observable<any> {
        this.getBearer();
        const url: string = `https://api.box.com/2.0/files/${path}`;
        return this.http.delete(url, {headers: this.headers })
        .pipe(
            map(
                (resp: any) => {
                    return resp;
                }
            ),
            catchError((this.handleError.bind(this)))
        )
    }
}