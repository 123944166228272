import { Component, Input } from '@angular/core';

@Component({
    selector: 'intm-text-high-l',
    template: `
        <div class="
            font-helvetica-now-display font-regular text-56 leading-62 -tracking-1-4
            m:text-28 m:leading-34 m:-tracking-0-6"
             [ngClass]="{
             'text-content-high': color=='high',
             'text-content-mid': color=='mid',
             'text-content-low': color=='low',
             'text-content-oncolor-high': color=='oncolor-high',
             'text-content-oncolor-mid': color=='oncolor-mid'
             }"
        >
            <ng-content></ng-content>
        </div>
    `
})
export class TextHighLComponent {
    @Input() color: 'high' | 'mid' | 'low' | 'oncolor-high' | 'oncolor-mid'= 'high';

}
