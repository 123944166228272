import {
    Component,
    Input,
    Output,
    forwardRef,
    EventEmitter,
    ViewEncapsulation,
    OnInit,
    inject,
    Injector,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroup,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel,
} from '@angular/forms';
import { CommonModule } from "@angular/common";

@Component({
    selector: 'intm-checkbox',
    templateUrl: './checkbox.component.html',
    standalone: true,
    imports: [
        CommonModule
    ],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => Checkbox),
            multi: true,
        },
    ],
})
export class Checkbox implements OnInit, ControlValueAccessor {
    @Input() id: string = '';
    @Input() value: false | true = false;
    @Input() disabled: true | false = false;

    @Output() changeEvent = new EventEmitter<boolean>();

    public control!: FormControl;

    injector = inject(Injector);

    ngOnInit(): void {
        const ngControl = this.injector.get(NgControl, null, { self: true, optional: true });
        if (ngControl instanceof NgModel) {
        this.control = ngControl.control;
    
        ngControl.control.valueChanges.subscribe((value) => {
            if (ngControl.model !== value || ngControl.viewModel !== value) {
            ngControl.viewToModelUpdate(value);
            }
        });
    
        } else if (ngControl instanceof FormControlDirective) {
            this.control = ngControl.control;
    
        } else if (ngControl instanceof FormControlName) {
            const container = this.injector.get(ControlContainer).control as FormGroup;
            this.control = container.controls[ngControl.name ? ngControl.name: ''] as FormControl;
    
        } else {
            this.control = new FormControl();
        }
    }

    public regOnChange = (_: any) => {};

    public regOnTouched = (_: any) => {};

    registerOnChange(fn: any): void {
        this.regOnChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.regOnTouched = fn;
    }

    writeValue(value: any) {
        if (value) {
            this.value = value;
        }
    }

    onChanged($event: Event) {
        const input = $event.target as HTMLInputElement;
        this.value = input.checked;
        this.regOnChange(this.value);
        this.changeEvent.emit(this.value);
    }
}