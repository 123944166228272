import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'timeElapsed'
})
export class TimeElapsedPipe implements PipeTransform {
    transform(date: Date | string, value:number, unit:'years' | 'months'): boolean {
        const now = new Date();
        const inputDate = new Date(date);

        let difference = 0;
        if(unit === 'years'){
            difference = now.getFullYear() - inputDate.getFullYear();

            // verify if the month is greater than the month of the input date
            if(now.getMonth() < inputDate.getMonth()){
                difference--;
            } else if(now.getMonth() === inputDate.getMonth() && now.getDate() < inputDate.getDate()){
                difference--;
            }

            return difference >= value;


        } else if(unit === 'months'){
            difference = (now.getFullYear() - inputDate.getFullYear()) * 12 + now.getMonth() - inputDate.getMonth();
            // verify if the day of the month is greater than the day of the input date
            if(now.getDate() < inputDate.getDate()){
                difference--;
            }
            return difference >= value
        }
        return false;
    }
}
