import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'app-simple-top-bar',
    template: `
        <div class="fixed top-0 flex px-32 py-16 justify-between gap-32 border-b border-b-stroke-low s:fixed s:top-0 w-full bg-base xs:px-16 z-50">
            <div class="flex gap-24 w-auto items-center">
                <img  src="assets/images/logos/logo-horizontal.svg" class="h-24"/>
            </div>
            @if (withButtonClose) {
                <div class="hover:cursor-pointer hover:opacity-50" (click)="close()">
                    <img src="assets/images/icons/close.svg"/>
                </div>
            }
        </div>
    `,
    standalone: true
})
export class SimpleTopBarComponent {
    @Input() withButtonClose: boolean = true;
    @Output() closeEvent = new EventEmitter<boolean>();

    constructor() {}

    close(): void {
        this.closeEvent.emit(true);
    }
}