import { Component, Input } from '@angular/core';

@Component({
    selector: 'intm-text-mid-m',
    template: `
            <div class="font-helvetica-now-medium font-medium text-24 leading-30 -tracking-1-2 m:text-16 m:leading-22 m:-tracking-0-4"
                 [ngClass]="{
                    'text-content-high': color=='high',
                    'text-content-mid': color=='mid',
                    'text-content-low': color=='low',
                    'text-content-oncolor-high': color=='oncolor-high',
                    'text-content-oncolor-mid': color=='oncolor-mid'
                 }"
            >
            <ng-content></ng-content>
        </div>
    `
})
export class TextMidMComponent {
    @Input() color: 'high' | 'mid' | 'low' | 'oncolor-high' | 'oncolor-mid'= 'high';
}
