import { Component, Input } from '@angular/core';

@Component({
    selector: 'intm-text-mid-l',
    template: `
        <div class="font-helvetica-now-medium font-medium text-32 leading-36 -tracking-1-4 m:text-24 m:leading-30 m:-tracking-1-2 xs:-tracking-1-3"
             [ngClass]="{
             'text-content-high': color=='high',
             'text-content-mid': color=='mid',
             'text-content-low': color=='low',
             'text-content-oncolor-high': color=='oncolor-high',
             'text-content-oncolor-mid': color=='oncolor-mid'
             }"
        >
            <ng-content></ng-content>
        </div>
    `
})
export class TextMidLComponent {
    @Input() color: 'high' | 'mid' | 'low' | 'oncolor-high' | 'oncolor-mid'= 'high';
}
