import { Component, Input } from "@angular/core";


@Component({
    selector: 'intm-button-danger',
    templateUrl: './danger.component.html',
    standalone: true
})
export class ButtonDanger {
    @Input('id') id: string = '';
    @Input('block') block: true | false = false;
    @Input('disabled') disabled: true | false = false;
    @Input('size') size: 'normal' | 'small' = 'small';
    @Input('type') type: 'submit' | 'button' | 'reset' = 'button';
    @Input('class') class: string = '';

    constructor() { }
}