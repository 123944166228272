
<div class="flex flex-col">
    @if(label){
        <intm-simple-label [for]="name" [disabled]="disabled" >{{label}}</intm-simple-label>
    }
    <div
        class=" flex justify-center items-center py-24 border-dashed border  rounded-8 cursor-pointer {{(control && control.touched && control.invalid || filesInvalid ) ? 'border-semantic-danger': isDragging ? 'border-semantic-primary':'border-overlay'}}"
        (click)="onDivClick()"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
        >
        <input
            #fileInput
            type="file"
            [multiple]="multiple"
            (change)="onFileSelected($event)"
            class="hidden"
            id="file-upload"
            [accept]="accept"
        />
        <label for="file-upload" (click)="stopPropagation($event)" class="flex s:flex-col s:px-16 s:text-center justify-center gap-8 s:gap-4 items-center cursor-pointer font-helvetica-now-regular font-regular text-14 leading-20 -tracking-0-5 text-content-mid">
            <img class="s:hidden" src="assets/images/icons/file/file.svg"/>
            <img class="hidden s:block" src="assets/images/icons/file/file-24.svg"/>
            @if(textContent){
                {{ textContent }}
            }
        </label>
    </div>
    @if(control && control.touched && control.invalid){
        <intm-message-helper-error [message]="messageErrorRequired"></intm-message-helper-error>
    }
    <!-- @if (control.value) {
        <div class="mt-16">
            @for(item of control.value; track item ){
                <div  class="flex items-center justify-between">
                    <div class="flex gap-8">
                        <div class="w-20 h-20">
                            @if(item.valid){
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                    <path d="M5.83268 11.9261L7.91602 14.0094L13.7493 8.17611M18.3327 10.6761C18.3327 15.2785 14.6017 19.0094 9.99935 19.0094C5.39698 19.0094 1.66602 15.2785 1.66602 10.6761C1.66602 6.07373 5.39698 2.34277 9.99935 2.34277C14.6017 2.34277 18.3327 6.07373 18.3327 10.6761Z" stroke="#05A36E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            }@else{
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <path d="M13.8332 7.00391L10.4998 10.3372M10.4998 10.3372L7.1665 13.6706M10.4998 10.3372L7.1665 7.00391M10.4998 10.3372L13.8332 13.6706M18.8332 10.3372C18.8332 14.9396 15.1022 18.6706 10.4998 18.6706C5.89746 18.6706 2.1665 14.9396 2.1665 10.3372C2.1665 5.73487 5.89746 2.00391 10.4998 2.00391C15.1022 2.00391 18.8332 5.73487 18.8332 10.3372Z" stroke="#D92626" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                            }
                        </div>

                        <span class="font-helvetica-now-regular font-regular text-14 leading-20 -tracking-0-5 text-content-mid">{{ item.name }}</span>
                    </div>
                    <div>
                        <button  (click)="removeFile($index)" class="font-helvetica-now-regular font-regular text-14 leading-20 -tracking-0-5 text-content-low hover:text-semantic-danger">{{labelRemove}}</button>
                    </div>
                </div>
            }
        </div>
    } -->
</div>
