import { Component, Input } from '@angular/core';

@Component({
    selector: 'intm-text-low-m',
    template: `
        <div class="font-helvetica-now-regular font-regular text-16 leading-22 -tracking-0-5 m:-tracking-0-6"
             [ngClass]="{
             'text-content-high': color=='high',
             'text-content-mid': color=='mid',
             'text-content-low': color=='low',
             'text-content-oncolor-high': color=='oncolor-high',
             'text-content-oncolor-mid': color=='oncolor-mid',
             'text-semantic-primary': color=='semantic-primary',
             'text-white': color==='white'
             }"
        >
            <ng-content></ng-content>
        </div>
    `
})
export class TextLowMComponent {
    @Input() color: 'high' | 'mid' | 'low' | 'oncolor-high' | 'oncolor-mid' | 'semantic-primary' | 'white' = 'high';
}
