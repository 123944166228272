<label for="" class="inline-flex items-center gap-8 cursor-pointer min-h-24 s:min-h-0 {{ disabled ? 'opacity-25 hover:cursor-not-allowed' : '' }} s:py-4"
    [htmlFor]="id"
>
    <div
        class="relative flex items-center cursor-pointer"
    >
        <input
            class="before:content[''] peer relative h-16 w-16 cursor-pointer appearance-none rounded-full border border-content-high transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-semantic-primary checked:before:bg-semantic-primary hover:before:opacity-5 disabled:cursor-not-allowed {{control && control.touched && control.invalid ? 'border-semantic-danger' : ''}}"
            type="radio"
            [id]="id"
            [name]="name"
            [disabled]="disabled"
            [value]="value"
            (change)="valueChanged(value)"
            [checked]="checked ? checked : value == control.value"
        >
        <span
            class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
        >   
            <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    width="10"
                    height="10"
                    rx="5"
                    fill="#0080F6"
                />
            </svg>
        </span>
    </div>
    <ng-content></ng-content>
</label>
