import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiV5Service } from "@app/service/api";
import { StorageService, ToolService } from "@app/service/utils";
import { environment } from "@environments/environment";
import { map } from "rxjs/operators";
import { IAuth } from "src/app/interfaces";
import { getRandomBackground } from "src/app/utils";
import { ApiV5AppService } from "../api/apiv5.app.service";

const API_V5 = environment.API_AC;
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private storage: StorageService,
        private apiV5Service: ApiV5Service,
        private toolService: ToolService,
        private apiV5AppService: ApiV5AppService,
    ) { }

    storeAuth(authData: IAuth) {
        this.storage.set('session', authData.authData);
        this.storage.set('users', authData.users);
    }

    getUserInfo() {
        return this.storage.get('users');
    }

    logout() {
        let userId: string = this.storage.get('users.id');
        const url: string = `${API_V5}/api/users/v5/user/auth/${userId}`;
        return this.apiV5Service.delete(url,'').pipe(
            map(
                (resp) => {
                    this.storage.clear();
                    const background: string = getRandomBackground();
                    this.storage.set('background', background);
                    return resp;
                }
            )
        );
    }

    login(data: {username: string, password: string}): Observable<any> {
        const url: string = `${API_V5}/api/users/v5/user/auth`;
        // convertimos username a uppercase
        data.username = data.username.toUpperCase();
        return this.apiV5Service.post(url, data).pipe(
            map((resp: IAuth) => {
                this.storeAuth(resp);
                    return resp.users
                }
            )
        )
    };


    /**
     * Funcion que se encarga de enviar un correo de cambio de contraseña
     * @param email
     */
    passwordRecovery(documentNumber: string) {
        const url: string = `${API_V5}/api/users/v5/user/auth/resetpassword`;
        return this.apiV5Service.get(url, { documentNumber: documentNumber });
    }


    passwordReset(data: {password: string, repeatPassword: string, hash: string}) {
        const url: string = `${API_V5}/api/users/v5/user/auth/reset`;
        return this.apiV5Service.put(url, data);
    }

    resetPasswordApp(data: {password: string, repeatPassword: string, hash: string}): Observable<any> {
        const url: string = `${this.toolService.getApiDomain()}/api/users/v5/user/auth/reset`;
        return this.apiV5AppService.put(url, data);
    }

    private static parseNameRol(rol: any) {
        if (rol && rol != '') {
            return rol.toLowerCase().replaceAll(' ', '_');
        }
        return '';
    }
}
