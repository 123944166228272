import { Injectable } from  '@angular/core';
import { LANG_DEFINITION } from '@app/utils/const'

@Injectable()
export class LangService {
    constructor() { }

    /**
     * Funcion que se encarga de obtener el lang de el localstorage y retornarlo
     * En caso de que no exista el lang en el localstorage se envia un lang por
     * defecto
     * @returns lang del tipo string
     */
    static getLang(): string {
        const defaultLang: string = LANG_DEFINITION.es; // Declaramos el lang por defecto que es 'es'
        if (localStorage.getItem('lang')) { // Verificamos si existe el item lang en la
            let storageLang: string | null = ''; // Declaramos una variable para obtener el lang
            storageLang = localStorage.getItem('lang'); // Seteamos el lang en la variable
            switch (storageLang) {
                case LANG_DEFINITION.es:
                case LANG_DEFINITION.it:
                case LANG_DEFINITION.pt:
                    // Si el lang coincide a cualquiera de los idiomas permitidos entonces lo retornamos
                    return storageLang;
                default: // En caso de que no exista retornamos el lang por defecto
                    return defaultLang;
            }
        }
        // Si no existe el lang en la sesion retornamos el lang por defecto
        return defaultLang;
    }
}
