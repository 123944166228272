import {
    Component,
    Input,
    Output,
    EventEmitter,
    signal,
    OnInit,
    forwardRef,
    ViewEncapsulation,
    inject,
    Injector
} from "@angular/core";
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroup,
    FormGroupDirective,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel,
} from "@angular/forms"
import { CommonModule } from "@angular/common"
import { InputType } from "../input.interface";
import { SimpleLabelComponent } from "@intm-ui/labels"
import { MessageHelperErrorComponent } from "@intm-ui/message-helper"

@Component({
    standalone: true,
    selector: "intm-text-input",
    imports: [
        CommonModule,
        SimpleLabelComponent,
        MessageHelperErrorComponent
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TextInputComponent)
        }
    ],
    encapsulation: ViewEncapsulation.None,
    templateUrl: "./text-input.component.html"
})
export class TextInputComponent implements InputType, OnInit, ControlValueAccessor {

    private injector = inject(Injector);

    //default atribute for input
    public type: "text" = "text";
    public control!: FormControl;

    //inputs atribute
    @Input() name = ""
    @Input() id = ""
    @Input() size: "normal" | "large" = "normal"
    @Input() placeholder = ""
    @Input() disabled = false;
    //input for label component
    @Input() label = ""
    @Input() value: string = '';
    @Input() index: string = '';

    //events of  the input
    @Output() changeEvent = new EventEmitter<string>();

    ngOnInit(): void {
        const ngControl = this.injector.get(NgControl, null, { self: true, optional: true });
        if (ngControl instanceof NgModel) {
            // ⬇ Grab the host control
            this.control = ngControl.control;

            // ⬇ Makes sure the ngModel is updated
            ngControl.control.valueChanges.subscribe((value) => {
                if (ngControl.model !== value || ngControl.viewModel !== value) {
                    ngControl.viewToModelUpdate(value);
                }
            });

        } else if (ngControl instanceof FormControlDirective) {
            this.control = ngControl.control;

        } else if (ngControl instanceof FormControlName) {
            const container = this.injector.get(ControlContainer).control as FormGroup;
            this.control = container.controls[ngControl.name ? ngControl.name: ''] as FormControl;

        } else {
            this.control = new FormControl();
        }
    }

    public regOnChange=(_: any) => {};
    public regOnTouched=(_: any) => {};

    set Value(val: string) {
        this.value = val;
        this.regOnChange(val);
        this.regOnTouched(val);
    }

    registerOnChange(fn: any): void {
        this.regOnChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.regOnTouched = fn;
    }


    writeValue(value: string): void {
        this.value = value;
    }

    /**
     * @description This method is called when the input value changes. It emits the new value through the valueChange event emitter.
     * @returns void
     * @param value
     */
    public onChanged($event: Event) {
        this.value = ($event.target as HTMLInputElement).value;
        this.regOnChange(this.value);
        this.changeEvent.emit(this.value);
        if (this.control) {
            this.control.markAllAsTouched();
        }
    }

    setDisabledState?(isDasabled: boolean): void {
        this.disabled = isDasabled
    }

    public clearValue() {
        this.value = '';
        this.regOnChange('');
        this.changeEvent.emit('');
    }

}
