import { computed, Injectable, signal } from "@angular/core";
import { ApiV5Service } from "../api";
import { environment } from "@environments/environment";
import { ICountry } from "@app/interface/data/country.interface";
import { LANG_DEFINITION } from "@app/utils/const";
import { StorageService, LangService } from "@app/service/utils";
import { ISelectOption } from "@app/interface/ui";
import { TranslateService } from "@ngx-translate/core";

const API_V5:string = environment.API_AC;

@Injectable()
export class DataService {

    //signal de listados
    provincesList: ISelectOption[] = [];
    daysList: ISelectOption[] = [];
    monthsList: ISelectOption[] = [];
    yearsList: ISelectOption[] = [];

    //lectura de signals
    //public countries = computed(()=> this.#countriesList());
   
    

    constructor(
        private apiV5Service: ApiV5Service,
        private storageService: StorageService,
        private translateService: TranslateService
    ){
        this.setDatesList(100, 0);
    }

    /**
     * Establece los años a futuro a mostrar
     * @param futureYears años a futuro a mostrar
     */
    public setFutureYears(futureYears: number): void {
        this.setDatesList(50, futureYears);
    }

    /**
     * Obtenemos los paises y guardamos el storage para llamadas futuras
     * @returns 
     */
    public getCountries(): Promise<ISelectOption[]> {
        return new Promise(
            (resolve, reject) => {
                let countriesListStorage = this.storageService.get('countries');
                if(countriesListStorage){
                    resolve(countriesListStorage);
                } else {
                    const url: string = `${API_V5}/api/policies/v5/country`;
                    this.apiV5Service.get(url).subscribe({
                            next: (countries: ICountry[]) => {
                                let countriesOptionList: ISelectOption[] = [];
                                countries.forEach((country: ICountry) => {
                                    let newCountry:ICountry ={
                                        id: country.id,
                                        idDyn: country.idDyn,
                                        name: country.name,
                                        isoCode3: country.isoCode3,
                                        isoCode2: country.isoCode2,
                                        isoCode: country.isoCode,
                                        provinceList: country.provinceList
                                    }
                                    countriesOptionList.push({
                                        text: newCountry.name ? newCountry.name : '',
                                        value: newCountry
                                    });
                                });
                                //guardamos el listado de paises en storage para futuras peticiones
                                this.storageService.set('countries', countriesOptionList)
                                resolve(countriesOptionList);
                            },
                            error: (error) => {
                                console.log(error)
                                reject(error);
                            },
                            complete: () => {}
                        })
                }
            });
    }

    /**
     *  Obtenemos las fechas de nacimiento
     *  @method getDates
     * @param yearsRange  Rango de años a mostrar
     * @param futureYears  Años futuros a mostrar
     * @returns 
     */
    private setDatesList(yearsRange:number, futureYears:number = 0): void {
        let dayArray: any[] = [];
        let monthArray: any[] = [];
        let yearArray: any[] = [];

        for (let day = 1; day <= 31; day++) {
            if(day < 10){
                dayArray.push('0' + day);
            }else{
                dayArray.push(String(day))
            }
        }

        for(let month = 1; month <= 12; month++){
            if(month < 10){
                monthArray.push('0' + month);
            }else{
                monthArray.push(String(month))
            }
        }

        // Declaramos una variable para obtener el anio acual, esto dependiendo
        // Si tenemos años futuros (por defecto tenemos cero)
        const maxDate: number = new Date().getFullYear() + futureYears;
        // Hacemos la iteración apartir del año actual previamente calculado
        for(let year = maxDate; year >= maxDate - yearsRange; year--) {
            // En cada iteración vamos reduciendo un año hasta llegar a nuestra fecha inicial
            yearArray.push(year); // Agregamos cada año a nuestro array de años
        }

        let daysOptionList:ISelectOption[] = [];
        let monthsOptionList:ISelectOption[] = [];
        let yearsOptionList:ISelectOption[] = [];

        dayArray.forEach((day: any) => {
            daysOptionList.push({
                text: day,
                value: day
            })
        });

        monthArray.forEach((month: any) => {
            monthsOptionList.push({
                text: month,
                value: month
            })
        });

        yearArray.forEach((year: any) => {
            yearsOptionList.push({
                text: year,
                value: year
            })
        });

        this.daysList = daysOptionList;
        this.monthsList = monthsOptionList;
        this.yearsList = yearsOptionList;
    }

    public  getTitleList(): Promise<ISelectOption[]> {
        return new Promise(
            (resolve, reject) => {
                this.translateService.get(
                    [
                        'registro.sr',
                        'registro.sra'
                    ]
                ).subscribe(
                    (texts: any) => {
                        resolve([
                            { text: texts['registro.sr'], value: 'Sr'},
                            { text: texts['registro.sra'], value: 'Sra'}
                        ])
                    }
                );
        });
    }
    

    public static getTypesDocumentList(): ISelectOption[] {
        let dataTypeDocument: ISelectOption[] = [];
        switch(LangService.getLang()){
            case LANG_DEFINITION.es:
                dataTypeDocument = [
                    {text: 'NIF', value: 'NIF'},
                    {text: 'NIE', value: 'NIE'},
                    {text: 'Pasaporte', value: 'Resto del mundo'},
                    {text: 'DNI Extranjero', value: 'Comunitario'}
                ]
                break;
            case LANG_DEFINITION.it:
                dataTypeDocument = [
                    {text: 'Codice fiscale', value: 'IT/CF'},
                    {text: 'Passaporto', value: 'Resto del mundo'},
                    {text: 'Documento straneiro', value: 'Comunitario'},
                ]
                break;
            case LANG_DEFINITION.pt:
                dataTypeDocument = [
                    {text: 'Cartão de Cidadão', value: 'PT/CC'},
                    {text: 'Pasaporte', value: 'Resto del mundo'},
                    {text: 'Documento straneiro', value: 'Comunitario'}
                ]
                break;
        }
        return dataTypeDocument;
    }

    public setProvinceByCountry(country: ICountry): void{
        this.provincesList = []
        
        if(!country.provinceList) return

        let provinceOptionList: ISelectOption[] = [];
        
        country.provinceList.forEach((province: any) => {
            provinceOptionList.push({
                text: province.name,
                value: province
            })
        });

        this.provincesList = provinceOptionList;
    }

}