import { map, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiV5Service } from "../api/apiV5.service";


const API_AC = environment.API_AC;

@Injectable(
    {providedIn: 'root'}
)
export class BoxApiService {
    constructor(
        private api: ApiV5Service
    ) {}

    getFolder(): Observable<any> {
        const url: string = `${API_AC}/api/claims/v5/claim/box/folder`;
        return this.api.get(url);
    }

    getToken(): Observable<any> {
        const url: string = `${API_AC}/api/claims/v5/claim/box/token`;
        return this.api.get(url);
    }

    makeUrlUploadFile() {
        const url: string = `${API_AC}/api/claims/v5/claim/box/document?origin=SF`;
        return url;
    }
}