<label class="inline-flex items-center cursor-pointer w-56 h-32 {{disabled ? 'opacity-20 hover:cursor-not-allowed' : ''}}">
    <input
        type="checkbox"
        [id]="id"
        [checked]="value"
        class="sr-only peer"
        [disabled]="disabled"
        (change)="onChanged($event)"
        (blur)="regOnTouched($event)"
    />
    <div
        class="relative w-56 h-32 peer-focus:outline-none peer-focus:ring-surface-blue3 dark:peer-focus:ring-surface-blue3 rounded-full peer dark:bg-surface-blue3 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white peer-checked:after:start-[-2px] after:rounded-full after:h-28 after:w-28 after:transition-all peer-checked:bg-semantic-primary dark:peer-focus:checked:ring-semantic-primary"
    ></div>
</label>
