import { NgModule} from "@angular/core";
import { CommonModule } from '@angular/common';
import { TextLowXSComponent } from '@intm-ui/typographic-tokens/text-low-xs';
import { TextLowSComponent } from '@intm-ui/typographic-tokens/text-low-s';
import { TextLowMComponent } from '@intm-ui/typographic-tokens/text-low-m';
import { TextLowLComponent } from '@intm-ui/typographic-tokens/text-low-l';

import { TextMidXSComponent } from '@intm-ui/typographic-tokens/text-mid-xs';
import { TextMidSComponent } from '@intm-ui/typographic-tokens/text-mid-s';
import { TextMidMComponent } from '@intm-ui/typographic-tokens/text-mid-m';
import { TextMidLComponent } from '@intm-ui/typographic-tokens/text-mid-l';

import { TextHighXSComponent } from '@intm-ui/typographic-tokens/text-high-xs';
import { TextHighSComponent } from '@intm-ui/typographic-tokens/text-high-s';
import { TextHighMComponent } from '@intm-ui/typographic-tokens/text-high-m';
import { TextHighLComponent } from '@intm-ui/typographic-tokens/text-high-l';
import { TextHighXLComponent } from '@intm-ui/typographic-tokens/text-high-xl';
import { TextLowLLowMComponent } from "./text-low-l-low-m";


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        TextLowXSComponent,
        TextLowSComponent,
        TextLowMComponent,
        TextLowLComponent,
        TextLowLLowMComponent,

        TextMidXSComponent,
        TextMidSComponent,
        TextMidMComponent,
        TextMidLComponent,

        TextHighXSComponent,
        TextHighSComponent,
        TextHighMComponent,
        TextHighLComponent,
        TextHighXLComponent
    ],
    exports: [
        TextLowXSComponent,
        TextLowSComponent,
        TextLowMComponent,
        TextLowLComponent,
        TextLowLLowMComponent,

        TextMidXSComponent,
        TextMidSComponent,
        TextMidMComponent,
        TextMidLComponent,

        TextHighXSComponent,
        TextHighSComponent,
        TextHighMComponent,
        TextHighLComponent,
        TextHighXLComponent
    ]
})
export class TypographicTokensModule { }
