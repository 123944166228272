import { Subscription } from 'rxjs';
import { ModalService } from '../service/modal.service';
import { Component, Input } from '@angular/core';


@Component({
    selector: 'intm-modal',
    templateUrl: './modal.component.html'
})
export class Modal {
    @Input() id: string = '';
    @Input() size: 'normal' | 'small' = 'normal';
    
    display: boolean = false;
    private subscription: Subscription | undefined;
    constructor(private modalService: ModalService) {}

    ngOnInit() {
        if (!this.id) {
            console.error('Debe de especificar el atributo id al modal');
            return;
        }
        this.subscription = this.modalService
            .watch(this.id)
            .subscribe(
                (status: string) => {
                    this.display = status === 'open' ? true : false;
                }
            );
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.modalService.unregister(this.id);
        }
        
    }

    closeModal() {
        this.modalService.close(this.id);
    }
}
