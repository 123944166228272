
import { CommonModule } from '@angular/common';
import {
    Component,
    Input,
    ViewEncapsulation,
    forwardRef,
    Injector,
    inject,
    Output,
    EventEmitter,
    OnInit,
    signal,
    computed,
    ViewChild,
    ElementRef
 } from '@angular/core';
import { TypographicTokensModule } from '@intm-ui/typographic-tokens/typographic-tokens.module';
import { debounceTime, Subject } from 'rxjs';
import { ControlContainer,
    ControlValueAccessor,
    FormGroup,
    FormControl,
    FormControlDirective,
    FormControlName,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel
} from '@angular/forms';
import { SimpleLabelComponent } from '@intm-ui/labels';
import { MessageHelperErrorComponent } from '@intm-ui/message-helper';
import { TextBoldPipe } from "./bold.pipe";
interface options {
    text: string,
    value: any,
    disable?: boolean
}

@Component({
    standalone: true,
    selector: 'intm-search-input',
    encapsulation: ViewEncapsulation.None,
    imports: [
        CommonModule,
        TypographicTokensModule,
        SimpleLabelComponent,
        MessageHelperErrorComponent,
        TextBoldPipe
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchInputComponent),
            multi: true
        }
    ],
    templateUrl: './search-input.component.html'
})
export class SearchInputComponent implements ControlValueAccessor, OnInit{
    @Input() value: any= undefined;
    @Input() results: options[] = [];
    @Input() options: options[]= [];
    @Input() notFound:string  = 'not found';
    @Input() placeholder = 'Search...';
    @Input() name = ""
    @Input() id = ""
    @Input() label = '';
    @Input() showAllResults = false;
    @Input() limit = 4;
    @Output() change = new EventEmitter<{text: string, value: any}>();
    isOpen: boolean = false;
    @ViewChild("searchInput") searchInput!: ElementRef<HTMLInputElement>;

    #showNotFound = signal<boolean>(false)

    public searchTerm:string = '';

    private searchSubject: Subject<string> = new Subject();
    public showNotFound = computed(()=>this.#showNotFound())

    public control!: FormControl;
    injector = inject(Injector);

    constructor(){}

    ngOnInit(): void {
        const ngControl = this.injector.get(NgControl, null, { self: true, optional: true });
        if (ngControl instanceof NgModel) {
        this.control = ngControl.control;

        ngControl.control.valueChanges.subscribe((value) => {
            if (ngControl.model !== value || ngControl.viewModel !== value) {
            ngControl.viewToModelUpdate(value);
            }
        });

        } else if (ngControl instanceof FormControlDirective) {
            this.control = ngControl.control;

        } else if (ngControl instanceof FormControlName) {
            const container = this.injector.get(ControlContainer).control as FormGroup;
            this.control = container.controls[ngControl.name ? ngControl.name: ''] as FormControl;

        } else {
            this.control = new FormControl();
        }

        if (this.value != undefined) {
            this.control.patchValue(this.value);
        }

        this.searchSubject.pipe(
            debounceTime(300)
        ).subscribe((searchTerm: string) => {
            let res = this.filterResults(searchTerm);
            if (res.length == 0) {
                this.#showNotFound.set(true);
                this.results = []
            }else{
                this.#showNotFound.set(false);
                this.results = res;
            }
        });
    }

    onSearch($event: Event) {
        this.searchTerm = ($event.target as HTMLInputElement).value;
        this.searchSubject.next(this.searchTerm.trim());
    }

    private filterResults(query: string): options[] {
        if (!query) return [];
        if (this.options) {
            return this.options.filter((option) => {
                return option.text.toLowerCase().includes(query.toLowerCase());
            });
        }
        return [];
    }

    public regOnChange = (_: any) => {};

    public regOnTouched = (_: any) => {};

    registerOnChange(fn: any): void {
        this.regOnChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.regOnTouched = fn;
    }

    writeValue(value: any) {
        if (value) {
            this.value = value;
        }
    }

    onChanged(value: options) {
        this.searchTerm = '';
        this.searchInput.nativeElement.value = value.text;
        this.isOpen = false;
        this.value = value;
        this.regOnChange(this.value);
        this.change.emit(this.value);
        this.results = [];
    }

    closeOnBlur($event: Event) {
        this.onSearch($event);
        setTimeout(() => {
            ($event.target as HTMLElement).blur();
            if(this.control) this.control.markAsTouched();
            this.results = [];
            this.#showNotFound.set(false);
            this.isOpen = false;
        }, 200)
    }

    open() {
        this.isOpen = true;
    }

    openSearch() {
        this.searchInput.nativeElement.focus();
        this.isOpen = true;
    }
}

