
import { Component, computed, signal } from '@angular/core';

@Component({
    selector: 'intm-menu-list',
    template:`
        <div class="flex flex-col gap-4 items-end">
            <div (click)="toggleMenu()" class="cursor-pointer" menuListClickOutside (clickOutside)="onOutsideClick()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18 14C16.8954 14 16 13.1046 16 12C16 10.8954 16.8954 10 18 10C19.1046 10 20 10.8954 20 12C20 13.1046 19.1046 14 18 14Z" fill="white"/>
                    <path d="M12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14Z" fill="white"/>
                    <path d="M6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C7.10457 10 8 10.8954 8 12C8 13.1046 7.10457 14 6 14Z" fill="white"/>
                </svg>
            </div>
            @if(showMenu()){
                <div class="fade-in flex flex-col gap-12 z-10 items-center w-fit bg-base py-12 px-16 rounded-8 cursor-pointer shadow-md" >
                    <ng-content></ng-content>
                </div>
            }
        </div> 
    `
})
export class MenuListComponent {

    #showMenu = signal<boolean>(false);

    public showMenu = computed(() => this.#showMenu());

    constructor(){}

    public toggleMenu(){
        this.#showMenu.set(!this.#showMenu());
    }

    public onOutsideClick(){
        this.#showMenu.set(false);
    }
}

