import {
    Component,
    Input,
    Output,
    EventEmitter,
    signal,
    OnInit,
    forwardRef,
    ViewEncapsulation,
    inject,
    Injector,
    ViewChild,
    ElementRef,
} from "@angular/core";
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroup,
    FormGroupDirective,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel,
} from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SimpleLabelComponent } from "@intm-ui/labels";
import { MessageHelperErrorComponent } from "@intm-ui/message-helper";
import { fileItem, fileItemError } from "./file-upload.interface";
import { values } from "lodash";

@Component({
    standalone: true,
    selector: "intm-file-upload",
    imports: [
        CommonModule,
        MessageHelperErrorComponent,
        SimpleLabelComponent
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FileUploadComponent)
        }
    ],
    templateUrl: "./file-upload.component.html"
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {

    private injector = inject(Injector);
    @Input() name = ""
    @Input() id = ""
    @Input() label = ""
    @Input() disabled = false;
    @Output() onAction = new EventEmitter<any>();
    @Output() filesSelected = new EventEmitter<FileList>();
    @Output() notifyError = new EventEmitter<fileItemError[]>();
    @Input() multiple:boolean = true;
    @Input() accept:string = ".pdf,.jpg,.jpeg,.png";
    @Input() textContent:string = "Haz click o arrastra aquí el archivo (Máximo 10Mb)";
    @Input() labelRemove:string = "Eliminar";
    @Input() maxSize:number = 10; //10 MB
    @Input() messageErrorFormat:string = "Formato de archivo incorrecto";
    @Input() messageErrorMaxSize:string = "El archivo excede el tamaño máximo permitido (10Mb)";
    @Input() messageErrorRequired:string = "Este campo es requerido";
    public filesInvalid: boolean = false;
    public isDragging: boolean = false;


    @ViewChild("fileInput") fileInput!: ElementRef<HTMLInputElement>;

    public control!: FormControl;

    protected selectedFiles: fileItem[] = [];
    protected Value: any;

    ngOnInit(): void {
        const ngControl = this.injector.get(NgControl, null, { self: true, optional: true });
        if (ngControl instanceof NgModel) {
            // ⬇ Grab the host control
            this.control = ngControl.control;

            // ⬇ Makes sure the ngModel is updated
            ngControl.control.valueChanges.subscribe((value) => {
                if (ngControl.model !== value || ngControl.viewModel !== value) {
                    ngControl.viewToModelUpdate(value);
                }
            });

        } else if (ngControl instanceof FormControlDirective) {
            this.control = ngControl.control;

        } else if (ngControl instanceof FormControlName) {
            const container = this.injector.get(ControlContainer).control as FormGroup;
            this.control = container.controls[ngControl.name ? ngControl.name: ''] as FormControl;

        } else {
            this.control = new FormControl();
        }
    }

    public regOnChange=(_: any) => {};
    public regOnTouched=(_: any) => {};

    public registerOnChange(fn: any): void {
        this.regOnChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.regOnTouched = fn;
    }

    public writeValue(obj: any): void {
        this.Value = obj;
    }

    public onDivClick(): void {
        this.fileInput.nativeElement.click();
    }

    public onFileSelected(event: Event): void {
        let files!: FileList;

        if (event instanceof DragEvent) {
            files = event.dataTransfer?.files as FileList;
        } else {
            const target = event.target as HTMLInputElement;
            files = target.files as FileList;
        }
        this.control.patchValue(files)
        this.onAction.emit(this.control.value);
    }

    public removeFile(index: number): void {
        this.selectedFiles.splice(index, 1);

        if (!this.existFileInvalid()) {
            this.filesInvalid = false;
        }
        this.emitFilesSelected();
    }

    /**
     * Emit the selected files
     * @private
     */
    private emitFilesSelected(): void {
        if (this.selectedFiles.length === 0 || this.filesInvalid) return
        const dataTransfer = new DataTransfer();
        this.selectedFiles.forEach(item => {
            dataTransfer.items.add(item.file);
        });
        this.regOnChange(dataTransfer.files);
        this.filesSelected.emit(dataTransfer.files);
    }

    public stopPropagation(event: Event){
        event.stopPropagation();
    }

    /**
     * Validate the file extension
     * @returns boolean
     * @param file
     */
    private validateFile(file: File): boolean {
        const allowedExtensions = this.accept.split(',');
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        return allowedExtensions.includes(`.${fileExtension}`);
    }

    /**
     * Validate the size of the file
     * @param file
     */
    private validateSizeFile(file: File): boolean {
        const fileSizeInMB = file.size / (1024 * 1024);
        return fileSizeInMB <= this.maxSize;
    }

    /**
     * Drag and drop events
     * @param event
     */
    public onDragOver(event: DragEvent): void {
        event.preventDefault();
        this.isDragging = true;
    }

    /**
     *  Drag and drop events
     * @param event
     */
    public onDragLeave(event: DragEvent): void {
        event.preventDefault();
        this.isDragging = false;
    }

    /**
     * Drag and drop events
     * @param event
     */
    public onDrop(event: DragEvent): void {
        event.preventDefault();
        this.isDragging = false;
        this.addSelectedFiles(event);
    }

    /**
     * Add selected files to the list of files
     * @param event - The event object
     * @private - This method is private and should only be called from within the class
     * @returns void
     */
    private addSelectedFiles(event: Event | DragEvent): void {
        let files!: FileList;

        if (event instanceof DragEvent) {
            files = event.dataTransfer?.files as FileList;
        } else {
            const target = event.target as HTMLInputElement;
            files = target.files as FileList;
        }
        let filesItemsTemp: fileItem[] = this.selectedFiles;
        let filesItemsWithError: fileItemError[] = []
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                if(!this.validateSizeFile(files[i])){
                    filesItemsWithError.push({message: this.messageErrorMaxSize, name: files[i].name});
                }
                if (!this.validateFile(files[i])) {
                    filesItemsTemp.push({ file: files[i], valid: false });
                    this.filesInvalid = true;
                    filesItemsWithError.push({message: this.messageErrorFormat, name: files[i].name});
                } else {
                    filesItemsTemp.push({ file: files[i], valid: true });
                }
            }
            this.selectedFiles = filesItemsTemp;
            console.log(this.control.value);
            this.emitFilesSelected();
            if(filesItemsWithError.length > 0){
                this.notifyError.emit(filesItemsWithError);
            }
        }

    }

    /**
     * Check if there are any invalid files
     * @returns boolean
     * @private
     */
    private existFileInvalid(): boolean {
        return this.selectedFiles.some(item => !item.valid);
    }
}
