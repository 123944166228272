import { Component,  Input} from "@angular/core";


@Component({
    selector: 'intm-skeleton',
    template: `
        @for(s of quantitySkeleton; track $index){
            <div role='status' class='w-full p-0 rounded-16 mb-16'>
                <div class="animate-pulse w-full bg-gray-300 h-[165px] rounded-lg mb-5 flex justify-center items-center"></div>
                <div class=' w-full p-24 -mt-[54px] bg-white rounded-16 flex justify-between items-start animate-pulse shadow-stroke-low'>
                    <div role='status' class='w-full animate-pulse'>
                        <h3 class='h-3 bg-gray-300 rounded-full  w-1/3 mb-4'></h3>
                        <p class='h-2 bg-gray-300 rounded-full w-1/3 mb-2.5'></p>
                        <p class='h-2 bg-gray-300 rounded-full w-1/2 mb-2.5'></p>
                        <p class='h-3 bg-gray-300 rounded-full w-1/3 mb-2.5'></p>
                    </div>
                </div>
            </div>
        }
    `,
    standalone: true,
})
export class SkeletonComponent {

    @Input() quantity:number = 3;
    public quantitySkeleton: number[] = [];

    constructor() {
        this.quantitySkeleton = Array(this.quantity).fill(0)
    }
}