import {
    Component,
    Input,
    Output,
    forwardRef,
    EventEmitter,
    ViewEncapsulation,
    OnInit,
    inject,
    Injector,
} from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroup,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel,
} from '@angular/forms';
import { TypographicTokensModule } from "@intm-ui/typographic-tokens/typographic-tokens.module";
import { MessageHelperErrorComponent } from "@intm-ui/message-helper";

@Component({
    selector: 'intm-file-upload-simple',
    templateUrl: './simple-upload.component.html',
    standalone: true,
    imports: [
        TypographicTokensModule,
        MessageHelperErrorComponent
    ],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadSimpleComponent),
            multi: true,
        },
    ],
})
export class FileUploadSimpleComponent implements OnInit, ControlValueAccessor {
    @Input({required: true }) id: string = '';
    @Input() value: File;
    @Input() disabled: true | false = false;
    @Input({required: true}) textContent: string = '';
    @Input() textLimit: string = '';

    @Output() changeEvent = new EventEmitter<File>();
    public isDragging: boolean = false;

    public control!: FormControl;

    injector = inject(Injector);

    ngOnInit(): void {
        const ngControl = this.injector.get(NgControl, null, { self: true, optional: true });
        if (ngControl instanceof NgModel) {
        this.control = ngControl.control;
    
        ngControl.control.valueChanges.subscribe((value) => {
            if (ngControl.model !== value || ngControl.viewModel !== value) {
            ngControl.viewToModelUpdate(value);
            }
        });
    
        } else if (ngControl instanceof FormControlDirective) {
            this.control = ngControl.control;
    
        } else if (ngControl instanceof FormControlName) {
            const container = this.injector.get(ControlContainer).control as FormGroup;
            this.control = container.controls[ngControl.name ? ngControl.name: ''] as FormControl;
    
        } else {
            this.control = new FormControl();
        }
    }

    public regOnChange = (_: any) => {};

    public regOnTouched = (_: any) => {};

    registerOnChange(fn: any): void {
        this.regOnChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.regOnTouched = fn;
    }

    writeValue(value: any) {
        if (value) {
            this.value = value;
        }
    }

    onChanged($event: Event) {
        const input = $event.target as HTMLInputElement;
        this.isDragging = false;
        if (input.files) {
            this.value = input.files[0];
            this.regOnChange(this.value);
            this.changeEvent.emit(this.value);
        }
    }

    public onDrop(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.isDragging = false;
        if (event.dataTransfer && event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0];
            this.value = file;
            this.regOnChange(this.value);
            this.changeEvent.emit(this.value);
        }
    }
    
    public onDragLeave(event: DragEvent): void {
        event.stopPropagation();
        this.isDragging = false;
    }

    public onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.isDragging = true;
        this.value = new File([], '');
    }
}