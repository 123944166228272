import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Modal } from './modal-stucture/modal.component';
import { ModalHeader } from "./header";
import { ModalBody } from "./body";
import { ModalFooter } from "./footer";
import { ModalService } from './service/modal.service';
import { TypographicTokensModule } from "@intm-ui/typographic-tokens/typographic-tokens.module";


@NgModule({ 
    imports: [
        CommonModule,
        TypographicTokensModule
    ],
    declarations: [
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter
    ],
    providers: [ModalService],
    exports: [
        Modal,
        ModalHeader,
        ModalBody,
        ModalFooter
    ]
})
export class ModalModule {}
