import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";


@Pipe({
    name: 'dateFormat',
    standalone: true
})
export class DateFormatPipe implements PipeTransform {
    constructor(
        private datePipe: DatePipe
    ) {}

    transform(value: Date | string | null, format: string = 'date'): string {
        if (!value) return '';

        const date = new Date(value);
        if (isNaN(date.getTime())) return '';

        if (format === 'date') {
            return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
        } else if (format === 'time') {
            return this.datePipe.transform(date, 'HH:mm:ss') || '';
        } else return '';
    }
}