import { Component, Input } from '@angular/core';

@Component({
    selector: 'intm-text-low-s',
    template: `
        <div class="font-helvetica-now-regular font-regular text-14 leading-20 -tracking-0-5"
            [ngClass]="{
                'text-content-high': color=='high',
                's:text-content-high': color.includes('s:high'),
                'text-content-mid': color.includes('mid'),
                'text-content-low': color=='low',
                'text-content-oncolor-high': color=='oncolor-high',
                'text-content-oncolor-mid': color=='oncolor-mid',
                'text-semantic-primary': color=='semantic-primary',
                'text-semantic-success': color=='semantic-success',
                'text-semantic-danger': color=='semantic-danger'
            }"
        >
            <ng-content></ng-content>
        </div>
    `
})
export class TextLowSComponent {
    @Input() color: 'high' | 'mid s:high' | 'mid' | 'low' | 'oncolor-high' | 'oncolor-mid'| 'semantic-primary'|'semantic-success'|'semantic-danger'= 'high';
    @Input() htmlContent: string = '';
}
