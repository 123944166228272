import { Component, Input } from "@angular/core";
import { StorageService } from "@app/service/utils";
import { ORIGIN_APP } from "@app/utils/const";
@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    standalone: true
})
export class TopbarComponent {

    @Input("hasBorder") hasBorder: boolean = false;
    @Input("isManaDomain") isManaDomain: boolean = false;

    constructor( private storageService: StorageService ){
        this.isManaDomain = this.isManaDomain || storageService.get('origin') == ORIGIN_APP.MANA;
    }
}