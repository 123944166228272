import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";


@Component({
    selector: 'app-logo-link',
    template: `
        <a class="hidden xs:block py-16 w-full"
            href="{{ 'login.url_tienda' | translate }}"
        >
            <img src="assets/images/logos/logo-horizontal.svg" alt="logo-intm" class="h-24">
        </a>
    `,
    standalone: true,
    imports: [TranslateModule]
})
export class LogoLinkComponent {
    constructor() {}
}