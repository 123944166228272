import { Component, Injector, Input, forwardRef, inject } from '@angular/core';
import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroup,
    NG_VALUE_ACCESSOR,
    NgControl,
    NgModel,
} from '@angular/forms';

@Component({
    selector: 'intm-radiobutton',
    templateUrl: './radio-buttons.component.html',
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioComponent),
            multi: true,
        },
    ],
})
export class RadioComponent implements ControlValueAccessor {
    @Input() id: string = '';
    @Input('value') _value: any;
    @Input() name: string | undefined;
    @Input() checked: true | false = false;
    @Input() disabled: true | false = false;

    modelValue: any;

    get value() {
        return this._value;
    }

    set value(value) {
        if (!!value) {
            this._value = value;
            this.onChange(value);
            this.onTouched();
        }
    }

    onChange: any = () => {};

    onTouched: any = () => {};

    registerOnChange(fn: Function) {
        this.onChange = fn;
    }

    registerOnTouched(fn: Function) {
        this.onTouched = fn;
    }

    writeValue(value: any) {
        this.modelValue = value;
    }

    toggleChange() {}

    valueChanged(event: any) {
        this.onChange(this.value);
    }

    public control!: FormControl;

    injector = inject(Injector);

    ngOnInit(): void {
        const ngControl = this.injector.get(NgControl, null, {
            self: true,
            optional: true,
        });
        if (ngControl instanceof NgModel) {
            this.control = ngControl.control;

            ngControl.control.valueChanges.subscribe((value) => {
                if (
                    ngControl.model !== value ||
                    ngControl.viewModel !== value
                ) {
                    ngControl.viewToModelUpdate(value);
                }
            });
        } else if (ngControl instanceof FormControlDirective) {
            this.control = ngControl.control;
        } else if (ngControl instanceof FormControlName) {
            const container = this.injector.get(ControlContainer)
                .control as FormGroup;
            this.control = container.controls[
                ngControl.name ? ngControl.name : ''
            ] as FormControl;
        } else {
            this.control = new FormControl();
        }
    }
}
